import React from "react";
import { ModalBody, Row, Col, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { toast } from "react-toastify";
import moment from "moment";
import { Typography, Button, Icon, Input } from "@plum-xoxo/stores-dsm";
import ApiService from "../../../../../api/api.service";
import { getColorFromTheme, isMobile } from "../../../../../utils/helpers";
import { Turnstile } from "@plum-xoxo/turnstile_captcha";
import { TURNSTILE_SITE_KEY } from "../../../../constants";
import { getShowCts } from "../../../../../store/appInfo/getters";
import { StoreState } from "../../../../../store/store.state";
import MediaQuery from "react-responsive";

const xoxoCodeStatusMap: any = {
  0: "Converted",
  1: "Active",
  2: "Used",
  3: "Expired",
  4: "Cancelled",
  6: "Converted",
};

interface Props {
  showmodal: Function;
  showCts: boolean;
}

interface AppliedVoucherDetails {
  voucherCode: string;
  value: any;
  remainingBalance: any;
  expiry: string;
  redirectUrl: string;
  isExpired: boolean;
  currency: any;
  currency_value: any;
  codeStatus: any;
}
interface State {
  btnstate: boolean;
  inputVal: string;
  remain_amount: number;
  expiry_date: string;
  isLoading: boolean;
  isCaptchaClicked: number;
  currency: string;
  currency_value: number;
  url: string;
  incorrect: boolean;
  renderTurnstileCaptcha: boolean;
  appliedVoucherDetails: AppliedVoucherDetails;
}

const initialAppliedVoucherDetails = {
  voucherCode: "",
  value: "",
  remainingBalance: "",
  expiry: "",
  redirectUrl: "",
  isExpired: false,
  currency: "",
  currency_value: "",
  codeStatus: 1,
};

class VoucherDetails extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      btnstate: true,
      inputVal: "",
      remain_amount: 0,
      expiry_date: "",
      isLoading: false,
      isCaptchaClicked: 0,
      currency: "",
      currency_value: 1,
      url: "",
      incorrect: false,
      renderTurnstileCaptcha: false,
      appliedVoucherDetails: initialAppliedVoucherDetails,
    };
  }

  toggle = () => {
    this.props.showmodal();
  };

  handlechange = (value: string) => {
    this.setState({ inputVal: value });
    if (!value || value.length < 6) {
      this.setState({ btnstate: true });
      return;
    } else {
      this.setState({ btnstate: false });
    }
  };

  getCfToken = () => {
    this.setState({ renderTurnstileCaptcha: true });
  };

  getVoucherDetails = ({ token = "" }) => {
    this.setState({
      isLoading: true,
      incorrect: false,
      currency: "",
      appliedVoucherDetails: initialAppliedVoucherDetails,
    });
    let reqParams: any = {
      code: this.state.inputVal
        ? this.state.inputVal.split("-").join("").trim()
        : "",
      partner_type: "vd",
      token,
    };

    try {
      ApiService.applyVoucher(reqParams).then((resData: any) => {
        this.setState({ renderTurnstileCaptcha: false });
        if (
          resData &&
          resData.success &&
          resData.data &&
          resData.data.Error === false
        ) {
          resData = resData.data;

          let detail_voucher: any =
            resData.Result && resData.Result.voucher_details;

          let url: string = resData.Result && resData.Result.url;

          let currency: string =
            resData.Result &&
            resData.Result.currency &&
            resData.Result.currency[0]?.code
              ? resData.Result.currency[0].code
              : "INR";

          let currency_value =
            resData.Result && resData.Result.currency[0]?.currency_value
              ? resData.Result.currency[0].currency_value
              : 1;

          if (resData.Result && resData.Result.stores === false) {
            toast.error(
              "Please check the campaign URL applicable under ‘Check Xoxo Code Balance’ in the top right corner of your screen"
            );
          } else if (
            resData.Result &&
            detail_voucher &&
            detail_voucher.status === 1
          ) {
            let voucherBalance: number = parseFloat(
              detail_voucher.remain_amount
            );
            if (isNaN(voucherBalance)) {
              toast.error("something went wrong !");
              return;
            } else if (voucherBalance === 0) {
              toast.error("The Xoxo code entered has already been used");
              return;
            }

            const voucherDetailsFromResp = {
              voucherCode: resData.Code,
              value: detail_voucher.amount,
              remainingBalance: detail_voucher.remain_amount,
              expiry: detail_voucher.expiry_date,
              redirectUrl: resData?.Result?.url || "",
              isExpired: new Date(detail_voucher.expiry_date) < new Date(),
              currency: currency,
              currency_value: currency_value,
              codeStatus: detail_voucher.status,
            };

            this.setState({
              appliedVoucherDetails: voucherDetailsFromResp,
              currency: currency,
              url: url,
              incorrect: false,
              inputVal: "",
            });
          } else if (resData.Result && detail_voucher) {
            const voucherDetailsFromResp = {
              voucherCode: resData.Code,
              value: detail_voucher.amount,
              remainingBalance: detail_voucher.remain_amount,
              expiry: detail_voucher.expiry_date,
              redirectUrl: resData?.Result?.url || "",
              isExpired: new Date(detail_voucher.expiry_date) < new Date(),
              currency: currency,
              currency_value: currency_value,
              codeStatus: detail_voucher.status,
            };
            this.setState({
              appliedVoucherDetails: voucherDetailsFromResp,
              currency: currency,
              incorrect: false,
              inputVal: "",
            });
          }
        } else {
          this.setState({ incorrect: true, isLoading: false });
        }
        this.setState({ isLoading: false, isCaptchaClicked: 0 });
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      btnstate,
      inputVal = "",
      isLoading,
      currency,
      incorrect,
      renderTurnstileCaptcha,
      appliedVoucherDetails,
    } = this.state;
    const { showCts } = this.props;
    return (
      <>
        <ModalBody>
          <MediaQuery maxDeviceWidth={991}>
            <Typography
              className="mb-5 text-center"
              textType="body"
              size="L"
              fontWeight="medium"
              color={getColorFromTheme("grey", "900")}
            >
              Xoxo Codes Balance Checker
            </Typography>
          </MediaQuery>
          <div
            className="mb-3 codeValue"
            style={{
              width: "400px",
              margin: "0 auto",
            }}
          >
            <Input
              type="text"
              size="medium"
              placeholder="Your Xoxo Code goes here"
              value={
                inputVal?.length
                  ? inputVal
                      .split("-")
                      .join("")
                      .match(/.{1,4}/g)
                      ?.join("-")
                  : ""
              }
              error={incorrect}
              helperText={
                incorrect ? "The Code entered is invalid. Try again!" : ""
              }
              maxLength={19}
              onChange={(e: any) =>
                this.handlechange(e.target.value.toString())
              }
            />
          </div>
          <div
            className="mt-4"
            style={{ margin: "0 auto", textAlign: "center" }}
          >
            <Button
              disabled={btnstate || isLoading || !inputVal}
              isLoading={isLoading}
              onClick={() => {
                if (!isLoading) {
                  showCts
                    ? this.getCfToken()
                    : this.getVoucherDetails({ token: "" });
                }
                return;
              }}
            >
              Check Voucher Balance
            </Button>
          </div>
          {renderTurnstileCaptcha ? (
            <Turnstile
              siteKey={TURNSTILE_SITE_KEY}
              onSuccess={(token: any) => this.getVoucherDetails({ token })}
              options={{ theme: "light" }}
              style={{ width: "100%" }}
            />
          ) : null}

          {!renderTurnstileCaptcha && currency ? (
            <div style={{ marginTop: 20 }}>
              <div
                style={
                  !isMobile()
                    ? {
                        padding: 8,
                        border: "1px solid #ECECEC",
                        borderRadius: 4,
                      }
                    : {}
                }
              >
                <div className="container">
                  <Row
                    style={{
                      padding: isMobile() ? "8px 0px" : "8px 16px",
                      backgroundColor: isMobile() ? "" : "#F6F6F6",
                      rowGap: 16,
                    }}
                  >
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                      <Typography
                        textType="body"
                        size="M"
                        fontWeight="medium"
                        color={getColorFromTheme("grey", "900")}
                      >
                        Voucher Code:
                      </Typography>
                      <Typography
                        textType="body"
                        size="M"
                        color={getColorFromTheme("grey", "900")}
                      >
                        {appliedVoucherDetails.voucherCode}
                      </Typography>
                    </Col>
                    <Col xs={4} sm={4} md="auto" lg="auto" xl="auto" xxl="auto">
                      <Typography
                        textType="body"
                        size="M"
                        fontWeight="medium"
                        color={getColorFromTheme("grey", "900")}
                      >
                        Value:
                      </Typography>
                      <Typography
                        textType="body"
                        size="M"
                        color={getColorFromTheme("grey", "900")}
                      >
                        {appliedVoucherDetails.currency}{" "}
                        {parseFloat(
                          (
                            appliedVoucherDetails.currency_value *
                            appliedVoucherDetails.value
                          ).toFixed(2)
                        )}
                      </Typography>
                    </Col>
                    <Col xs={4} sm={4} md="auto" lg="auto" xl="auto" xxl="auto">
                      <Typography
                        textType="body"
                        size="M"
                        fontWeight="medium"
                        color={getColorFromTheme("grey", "900")}
                      >
                        Balance:
                      </Typography>
                      <Typography
                        textType="body"
                        size="M"
                        color={getColorFromTheme("grey", "900")}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ columnGap: 4 }}
                        >
                          <div>
                            {appliedVoucherDetails.currency}{" "}
                            {parseFloat(
                              (
                                appliedVoucherDetails.currency_value *
                                appliedVoucherDetails.remainingBalance
                              ).toFixed(2)
                            )}
                          </div>
                          {appliedVoucherDetails.remainingBalance !== 0 &&
                          appliedVoucherDetails.remainingBalance !==
                            appliedVoucherDetails.value ? (
                            <>
                              <UncontrolledTooltip
                                placement="right"
                                target="xoxoCodePartialRedem"
                              >
                                This voucher is partially redeemed
                              </UncontrolledTooltip>
                              <div id="xoxoCodePartialRedem">
                                <Icon
                                  iconName="InfoCircle"
                                  fill={getColorFromTheme("grey", "900")}
                                  size={16}
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </Typography>
                    </Col>
                    <Col xs={4} sm={4} md="auto" lg="auto" xl="auto" xxl="auto">
                      <Typography
                        textType="body"
                        size="M"
                        fontWeight="medium"
                        color={getColorFromTheme("grey", "900")}
                      >
                        Status:
                      </Typography>
                      <Typography
                        textType="body"
                        size="M"
                        component="span"
                        color={getColorFromTheme(
                          appliedVoucherDetails.codeStatus === 1 ||
                            appliedVoucherDetails.codeStatus === 2
                            ? "green"
                            : "red",
                          "300"
                        )}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ columnGap: 4 }}
                        >
                          <div>
                            {
                              xoxoCodeStatusMap[
                                appliedVoucherDetails.codeStatus
                              ]
                            }
                          </div>
                          <div>
                            <Icon
                              iconName={
                                appliedVoucherDetails.codeStatus === 1 ||
                                appliedVoucherDetails.codeStatus === 2
                                  ? "CheckCircle"
                                  : "Alert"
                              }
                              fill={getColorFromTheme(
                                appliedVoucherDetails.codeStatus === 1 ||
                                  appliedVoucherDetails.codeStatus === 2
                                  ? "green"
                                  : "red",
                                "300"
                              )}
                              size={16}
                            />
                          </div>
                        </div>
                      </Typography>
                    </Col>
                    <Col xs={4} sm={4} md="auto" lg="auto" xl="auto" xxl="auto">
                      <Typography
                        textType="body"
                        size="M"
                        fontWeight="medium"
                        color={getColorFromTheme("grey", "900")}
                      >
                        Expiry:
                      </Typography>
                      <Typography
                        textType="body"
                        size="M"
                        color={getColorFromTheme("grey", "900")}
                      >
                        {moment(
                          appliedVoucherDetails.expiry
                            ? appliedVoucherDetails.expiry.split("T")[0]
                            : new Date()
                        ).format("DD/MM/YYYY")}
                      </Typography>
                    </Col>
                  </Row>
                  {appliedVoucherDetails.codeStatus === 1 ? (
                    <>
                      <hr
                        style={{
                          margin: "10px 0px",
                          borderTop: `1px solid ${getColorFromTheme(
                            "grey",
                            "200"
                          )}`,
                        }}
                      />
                      <Button
                        className="w-100"
                        color="text"
                        size="small"
                        endIcon={
                          <Icon
                            iconName="ExternalLink"
                            size={20}
                            fill={getColorFromTheme("grey", "900")}
                          />
                        }
                      >
                        <a
                          href={appliedVoucherDetails.redirectUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Typography
                            textType="body"
                            size="XS"
                            color={getColorFromTheme("grey", "800")}
                          >
                            Go to reward URL
                          </Typography>
                        </a>
                      </Button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </ModalBody>
      </>
    );
  }
}
const mapStateToProps = ({ appInfo }: StoreState) => ({
  showCts: getShowCts(appInfo),
});
export default connect(mapStateToProps, null)(VoucherDetails);
