import queryString from "query-string";
import { DEFAULT_PREFIX } from "../commons/constants/routes.constants";
import { theme } from "@plum-xoxo/stores-dsm";
import { RazorpaySupportedCurrencies } from "../commons/constants";

const { DEFAULT = "marketplace" } = DEFAULT_PREFIX;
const cookiesDomain = process.env.REACT_APP_COOKIES_DOMAIN;

export const isLengthyArray = (item: any) =>
  Array.isArray(item) && item.length > 0;

export const getNestedValue = (
  object: any,
  propertyName: string,
  defaultValue?: any
): any => {
  const value = propertyName.split(".").reduce(getValue, object);
  return value !== undefined ? value : defaultValue;
};

export const validateEmail = (string: string) => {
  const str = string.trim();
  var reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(str);
};

export const validatePassword = (pwd: string) => {
  var reg = new RegExp("^(?!.* )(?=.*[0-9])(?=.{8,})");
  return reg.test(pwd);
};

export const getValue = (object: any, propertyName: string): any => {
  if (!propertyName) {
    throw new Error("Impossible to set null property");
  }
  return object === null || typeof object === "undefined"
    ? undefined
    : object[propertyName];
};

export const capitalize = (value: string) => {
  if (!value) {
    return value;
  }
  return (
    value && value.charAt(0) && value.charAt(0).toUpperCase() + value.slice(1)
  );
};

export const capitalizeEachWord = (string: string) => {
  if (!string) {
    return string;
  }
  const words =
    string &&
    string.split(" ").map((item) => {
      return capitalize(item);
    });
  return words && words.join(" ");
};

export const stripHtml = (string: string): string => {
  if (string) {
    return string
      .replace(/&#39;/g, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/&nbsp; |&lt; |&nbsp;|&gt;/g, " ")
      .replace(/&amp;/g, "&")
      .replace(/&lt;p/g, "")
      .replace(/& amp;/g, "&")
      .replace(/<[^>]*>/g, "")
      .replace(/[`~!@#$^*()|+\-=?;:'".<>{}[]\\\/]/gi, "")
      .replace(/_/g, ", ");
  } else {
    return "";
  }
};

export const decodeHtmlCode = (str: string) => {
  if (str) {
    const parsed = str.replace(/&amp;/g, "&").replace(/& amp;/g, "&");
    return parsed.replace(/&#([0-9]+);?/g, function (full, int) {
      return String.fromCharCode(parseInt(int));
    });
  } else {
    return "";
  }
};

export const createMarkup = (text: string): { __html: string } => {
  return {
    __html: text
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace("/&amp;/g", "&")
      .replace(/(Â­)/gm, " "),
  };
};

export const stripPfromString = (text: string): any => {
  let textArray: any = text.match(/<p>.*?<\/p>/g) || [];
  return textArray;
};

export const getCookie = (n: string): string => {
  let a: any = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
  return a ? a[1] : "";
};

export const getCookieToken = (n: string): string => {
  let a: any = `; ${document.cookie};domain= ${cookiesDomain};`.match(
    `;\\s*${n}=([^;]+)`
  );
  return a ? a[1] : "";
};

export const deleteCookie = (cookieName: string) => {
  document.cookie =
    cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const localStorageTest = () => {
  try {
    localStorage.setItem("test", "test");
    localStorage.getItem("test");
    localStorage.removeItem("test");
    return true;
  } catch (e) {
    return false;
  }
};

export const STORAGE = (key: any, value?: any, remove?: any) => {
  if (localStorageTest() === true) {
    if (value) {
      localStorage.setItem(key, value);
      return;
    }

    let temp = localStorage.getItem(key);
    if (remove) {
      localStorage.removeItem(key);
    }
    return temp;
  } else {
    // store in browser cookies
    if (value) {
      let expiry = new Date(Number(new Date()) + 30 * 24 * 3600000).toString();
      document.cookie = key + "=" + value + ";path=/;expires=" + expiry + ";";
      return;
    }
    let all = document.cookie.split(";");
    let temp = null;
    for (let i in all) {
      let keyAndValue = all[i].split("=");
      if (keyAndValue[0].trim() === key.trim()) {
        temp = keyAndValue[1];
        break;
      }
    }
    if (remove) {
      document.cookie = key + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    }
    return temp;
  }
};

export const createQueryString = (queryStringProps: any) => {
  //Don't show empty values in url.
  Object.keys(queryStringProps).forEach((key) => {
    if (!queryStringProps[key] && !(queryStringProps[key] === 0)) {
      delete queryStringProps[key];
    }
  });
  return Object.keys(queryStringProps)
    .filter((item) => item)
    .map((key) => {
      if (Array.isArray(queryStringProps[key])) {
        return queryStringProps[key]
          .map((item: any) => `${key}[]=${encodeURIComponent(item)}`)
          .join("&");
      }
      return `${key}=${encodeURIComponent(queryStringProps[key])}`;
    })
    .filter((item) => item)
    .join("&");
};

export const getUrlQueryParams = (req?: { search: string }) => {
  let searchObject = "";
  searchObject = window.location.search;

  return queryString.parse(searchObject, {
    arrayFormat: "bracket",
  });
};

export const convertToSlug = (Text: string) => {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const amountConversion = (
  conversionFactor: number,
  amount: number,
  currencyValue: number,
  noRoundOff: boolean = false
) => {
  amount = (amount * currencyValue * 100) / conversionFactor;
  return noRoundOff ? amount / 100 : Math.round(amount) / 100;
};

export const roundOfAmount = (amount: any) => {
  return Math.round((amount * 100) / 100);
};

export const isValidEmailId = (email: string) => {
  // let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]{2,3})+$/;
  let mailformat =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return email.match(mailformat);
};

export const setCookie = (sso_token: any) => {
  document.cookie = sso_token;
  window.location.replace("/");
};
export const setGlobalCookie = (
  name: any,
  value: any,
  domain: any = window.location.hostname,
  minutes: any = -1
) => {
  var expires = "";
  if (minutes != -1) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = date.toUTCString();
  } else {
    //assuming the cookie should be everlasting here
    expires = "Fri, 31 Dec 9999 23:59:59 GMT";
  }
  document.cookie =
    name +
    "=" +
    (value || "") +
    "; expires=" +
    expires +
    "; domain=" +
    domain +
    "; path=/";
};

export const numberFormat = (value: any) => {
  if (!value) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isMobile = (): boolean => window.innerWidth <= 991;

export const defaultCustomUrl = (custom_url: string) => {
  if (custom_url === "/" || custom_url === "") {
    custom_url = `/${DEFAULT}`;
  }
  return custom_url;
};

export const shuffleArray = (array: any) => {
  let pos, temp;
  for (let i = array.length - 1; i > 0; i--) {
    pos = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[pos];
    array[pos] = temp;
  }
  return array;
};

export const checkIfPrePaidCardExistsInCart = (
  cartVouchers: any,
  product_id: number,
  max_order_quantity: any
) => {
  if (!max_order_quantity) {
    return false;
  } else {
    const indexNo = cartVouchers.findIndex(
      (voucher: any) => voucher.productId === product_id
    );
    if (indexNo !== -1) {
      return true;
    }
    return false;
  }
};

export const validatePhoneNo = (number: string) => {
  const regex = /^\d{7,16}$/;
  const validatePhone = regex.test(number);
  return validatePhone;
};

export const getColorFromTheme = (category: string, colorReq: string) => {
  return theme.palette[category][colorReq];
};

export const getFontWeightFromTheme = (weight: string) => {
  return theme.typography.fontWeight[weight];
};

export const getShadowFromTheme = (value: string) => theme.shadows[value];
export const checkIfImageExists = (
  url: string,
  callback: (exists: boolean) => void
) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

export const getUrlWithBranding = (custom_url: string) => {
  return custom_url === "/" ? "/marketplace" : custom_url;
};

export const splitMerchandiseVouchersData = (
  cartVouchers: any = [],
  merchandiseCatId: any = []
) => {
  let merchandiseProducts = [],
    giftVoucherProducts = [];
  try {
    merchandiseProducts = cartVouchers.filter(
      (item: any) => JSON.parse(item?.info || "{}")?.isMerchandiseProduct
    );

    giftVoucherProducts = cartVouchers.filter(
      (item: any) => !JSON.parse(item?.info || "{}")?.isMerchandiseProduct
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
  return { merchandiseProducts, giftVoucherProducts };
};

export const getMercGvsTotal = (mercData: any, gvData: any) => {
  let merchandiseTotal = 0;
  let giftVoucherTotal = 0;
  mercData.forEach(
    (item: any) => (merchandiseTotal += item.quantity * item.price)
  );
  gvData.forEach(
    (item: any) => (giftVoucherTotal += item.quantity * item.price)
  );
  return { merchandiseTotal, giftVoucherTotal };
};

export const checkForCrossCountryMerchandiseProd = (
  cartVouchers: any = [],
  merchandiseCatId: any = [],
  country_name: any = ""
) => {
  const splitData = splitMerchandiseVouchersData(
    cartVouchers,
    merchandiseCatId
  );
  const merchandiseProducts = splitData.merchandiseProducts;
  const merchandiseProductsInfo =
    merchandiseProducts?.length > 0
      ? JSON.parse(merchandiseProducts[0].info)
      : {};
  const cartCountry = Object.keys(merchandiseProductsInfo).length
    ? merchandiseProductsInfo.country_name
    : "";

  if (cartCountry && cartCountry !== country_name) {
    return true;
  }
  return false;
};

export const getHiddenXoxoCode = (code: string = "") => {
  return (
    code.substring(0, 2) +
    "xxxxxxxx" +
    code.substring(code.length, code.length - 2)
  );
};

export const getRedeemableXoxoPoints = (
  xoxoPointsAvailable: any = 0,
  payableAmount: any,
  cartToBaseConversionValue: any
) => {
  let finalGcbUsed = xoxoPointsAvailable;
  let converted_gcbUsed = amountConversion(
    cartToBaseConversionValue,
    finalGcbUsed,
    1,
    true
  );
  if (payableAmount <= converted_gcbUsed) {
    converted_gcbUsed = payableAmount;
  }
  payableAmount -= converted_gcbUsed;
  finalGcbUsed = converted_gcbUsed;
  return { finalGcbUsed, payableAmount };
};

export const checkRangeOfVariableDenomination = (
  inputPrice: any,
  min_price: any,
  max_price: any
) => {
  if (min_price || max_price) {
    return inputPrice <= max_price && inputPrice >= min_price;
  } else {
    return inputPrice <= 10000 && inputPrice >= 10;
  }
};

export const checkIfUnAuthorisedAccess = (statusCode: number = 200) => {
  if (statusCode && statusCode === 401) {
    return true;
  }
  return false;
};

export const getPaymentGatewayFromCurrency = (currencyCode: string = "INR") => {
  if (RazorpaySupportedCurrencies.includes(currencyCode)) {
    return "razorpay";
  }
  return "primer";
};
