import React from "react";
import { connect } from "react-redux";
import {
  getLinkedAccounts,
  getPlatFormSettings,
} from "../store/appInfo/getters";
import { StoreState } from "../store/store.state";
import { get } from "lodash";
import { getLoginStatus } from "../store/user/getters";

interface Props {
  linkedAccountsData: any;
  children: any;
  user: any;
  selectedCountry: any;
  platformSettings: any;
  isLoggedIn: any;
  userContact?: any;
}

const CheckForPerks: React.FC<Props> = ({
  children,
  linkedAccountsData = [],
  user = {},
  selectedCountry = {},
  platformSettings = {},
  isLoggedIn,
  userContact = {},
}) => {
  const { iso_code_2 = "" } = selectedCountry;
  const { enablePerks = 0 } = platformSettings;
  const userDetails = user?.userDetails;
  const company_id = userDetails?.company_id || "0";
  const primary_identifier = userContact?.primary_identifier || 1;

  const selectedCompany =
    (linkedAccountsData?.length &&
      linkedAccountsData.find((item: any) => item.cid === company_id)) ||
    {};
  const modulesLinkedData =
    get(
      selectedCompany,
      "moduleAccess.data.getMultiUserModuleAccess.data",
      []
    ) || [];

  const { isAdmin = false } = selectedCompany;
  const isPointsModuleEnabled = isAdmin
    ? modulesLinkedData.some((o: any) => {
        return o.module_id == 2;
      })
    : enablePerks;

  const showPerksTab =
    isLoggedIn &&
    iso_code_2 === "IN" &&
    enablePerks &&
    isPointsModuleEnabled &&
    primary_identifier === 1;

  if (!showPerksTab) {
    return null;
  }

  return children;
};

const mapStateToProps = ({ appInfo, user }: StoreState) => ({
  selectedCountry: appInfo.selectedCountry,
  linkedAccountsData: getLinkedAccounts(appInfo),
  platformSettings: getPlatFormSettings(appInfo),
  user,
  isLoggedIn: getLoginStatus(user),
  userContact: user.userDetails,
});

export default connect(mapStateToProps)(CheckForPerks);
