/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _startCase from "lodash/startCase";
import { StoreState } from "./store/store.state";
import { resetCart } from "./store/cart/actions";
import { fetchCartWithThunk } from "./store/cart/thunks";
import {
  getUserDetails,
  getLoginStatus,
  getUserPoints,
} from "./store/user/getters";
import {
  setUserDetails,
  resetUserData,
  setLoginStatus,
  setUserPoints,
  setGCB,
  setIsAdmin,
  setXoxoTokens,
  setUserCompany,
} from "./store/user/actions";
import {
  getBranding,
  getRoutes,
  getPlatFormSettings,
} from "./store/appInfo/getters";
import {
  setBranding,
  setRoutes,
  setVoucherCountryList,
  setCountryList,
  setPlatformSettings,
  setVoucherCategoryList,
  setExperienceCategoryList,
  setShowAlternateEmail,
  setLinkedAccounts,
  toggleLinkedAccountsModal,
  setShowMultiLanguage,
  setPhnNumDisableData,
  setUserJourneyState,
  setShowCts,
  setUserCountry,
  setSelectedCountry,
  setUserCountryCode,
  setOriginCountryCode,
  setMerchandiseCategoryList,
  setMerchCountryList,
} from "./store/appInfo/actions";
import {
  LOGIN_COOKIE_NAME,
  DEFAULT_PLATFORM_SETTINGS,
  PLUM_ROUTES,
  DEFAULT_PREFIX,
} from "./commons/constants";
import { Points, GCB, Tokens, CompanyDetails } from "./store/user/types";
import ApiService from "./api/api.service";
import { STORAGE, getCookieToken, setGlobalCookie } from "./utils/helpers";
import { PageLoader } from "./commons/constants/loadableImports";

const theme_prefix = process.env.REACT_APP_WHITE_COLOR_PREFIX;

const ROUTE_NAME = {
  EXPERIENCES: "experiences",
  VOUCHERS: "vouchers",
  PERKES: "perks",
  MERCHANDISES: "merchandises",
};

let routeList = [
  ROUTE_NAME.EXPERIENCES,
  ROUTE_NAME.VOUCHERS,
  ROUTE_NAME.PERKES,
  ROUTE_NAME.MERCHANDISES,
];

// Use Intervel Hook
function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<(() => void) | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Bootstrap = (props: any) => {
  const { isLoggedIn } = props;
  const isLogin = getCookieToken(LOGIN_COOKIE_NAME) === "1";
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [custom_url, setCustomUrl] = useState("/");
  const history = useHistory();

  function updatePrimaryColor(primary: string) {
    const updateStyles = document.createElement("style");
    updateStyles.innerHTML = `.headerActions {border-bottom: 2px solid ${primary} }`;
    document.body.appendChild(updateStyles);
  }

  function getPlatformSetting(branding: any, appending_url: string = "") {
    const data = { urlPrefix: branding.custom_url };
    ApiService.getPlatformSetting(data, isLoggedIn)
      .then((response: any) => {
        const platformSetting = response?.data?.fetchPlatformSetting;
        if (platformSetting.success === 1 && platformSetting.data) {
          const primaryLogo = platformSetting?.data?.primaryLogo || "";
          if (primaryLogo !== "") {
            branding.logo_url = primaryLogo;
            props.setBranding(branding);
            STORAGE("logo_url", primaryLogo);
          }
          props.setPlatformSettings(platformSetting.data);
        } else {
          props.setPlatformSettings(DEFAULT_PLATFORM_SETTINGS);
          if (
            !appending_url.includes("marketplace") &&
            (appending_url.includes("rp") ||
              appending_url.includes("reset-password"))
          ) {
            history.push(`/marketplace${appending_url}`);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }

  function getRoutes(custom_url: string, appending_url: string = "") {
    const payload = { url: custom_url, key: "vd" };
    if (custom_url === "/" || !custom_url) {
      props.setRoutes(routeList);
      setIsLoading(false);
      fetchAppData(routeList, custom_url);
      return;
    }
    const data = ApiService.getRoutes(payload, isLogin);
    data
      .then((data: any) => {
        if (data?.getRoutes?.output) {
          const response = JSON.parse(data.getRoutes.output);

          if (
            response?.success === 1 &&
            response?.data &&
            response?.data?.length
          ) {
            routeList = response.data.map((route: string) =>
              route ? route.replace("merchandise", "merchandises") : route
            );
            props.setRoutes(routeList);
          } else {
            props.setRoutes(routeList);
          }
          fetchAppData(routeList, custom_url);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        fetchAppData(routeList, custom_url);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // checks if company url if valid
  function handleSetCustomUrl(route: any, custom_url: any, appending_url: any) {
    const endPoint = `/api/${isLogin ? "" : "public/"}graph`;
    const data = ApiService.setCustomUrl(custom_url, endPoint);
    const isInternalRoute = PLUM_ROUTES.includes(custom_url.split("/")[1]);
    data
      .then((data: any) => {
        if (data?.sitesPreferences?.success) {
          const branding = {
            logo_url: data.sitesPreferences?.brandLogo,
            custom_route: route,
            custom_url:
              custom_url === "/marketplace" || custom_url === "/admin"
                ? "/"
                : custom_url,
          };
          const showCts = data?.sitesPreferences?.showCts || false;
          setCustomUrl(branding.custom_url);
          getPlatformSetting(branding);

          props.setBranding(branding);
          props.setShowCts(showCts);

          if (data?.sitesPreferences?.brandLogo) {
            STORAGE("logo_url", data?.sitesPreferences?.brandLogo);
          } else {
            STORAGE("logo_url", "", true);
          }
        } else {
          const branding = {
            logo_url: "",
            custom_route: "/:keyword",
            custom_url: "/",
          };
          getPlatformSetting(branding, custom_url);
          const showCts = data?.sitesPreferences?.showCts || false;
          props.setShowCts(showCts);
          props.setBranding(branding);
        }
      })
      .catch((err) => {});
    if (!isInternalRoute) {
      getRoutes(
        custom_url === "/marketplace" || custom_url === "/admin"
          ? "/"
          : custom_url,
        appending_url
      );
    } else {
      fetchAppData(routeList, custom_url);
    }
  }

  // fetch company details if company url is present
  function getCustomUrl(custom_url: string = "", loggedIn: boolean = false) {
    const { branding }: any = props;
    let url_following = window.location.pathname.toLowerCase().split("/");

    if (url_following[url_following.length - 1] === "") {
      url_following.pop();
    }

    if (url_following?.length > 1) {
      if (url_following?.length >= 2) {
        let custom_route = "/:keyword";
        let current_custom_url = "/";
        let route = "";
        if (loggedIn) {
          if ((PLUM_ROUTES || []).includes(url_following[1])) {
            window.location.href = custom_url;
          } else {
            url_following[1] = custom_url?.replace("/", "") || "";
          }
        }
        if (
          url_following.length > 2 &&
          (PLUM_ROUTES || []).includes(url_following[2])
        ) {
          current_custom_url = `/${url_following[1]}`;
          route = url_following[2] || "";
        } else if (url_following.length > 2) {
          custom_route = "/:keyword/:keyword";
          current_custom_url = `/${url_following[1]}/${url_following[2]}`;
          route = url_following[3] || "";
        } else if (
          url_following[1] !==
          ((DEFAULT_PREFIX && DEFAULT_PREFIX.DEFAULT) || "marketplace")
        ) {
          current_custom_url = `/${url_following[1]}`;
          route = url_following[2] || "";
        } else {
          route = url_following[2] || "";
        }
        if (!route) {
          props.setBranding({
            ...branding,
            custom_url: current_custom_url,
          });
        }
        handleSetCustomUrl(custom_route, current_custom_url, route);
      }
    } else if (loggedIn) {
      props.setBranding({ ...branding, custom_url });
      history.push(custom_url);
      getCustomUrl(custom_url, true);
    } else {
      props.setBranding({ ...branding, custom_url: "/marketplace" });
      fetchAppData(routeList, custom_url);
      history.push("/marketplace");
      setIsLoading(false);
    }
  }

  function setUserCountryFromIp(apiResponse: any, countryList: any = []) {
    const {
      setUserCountry,
      setUserCountryCode,
      setSelectedCountry,
      setOriginCountryCode,
    }: any = props;

    if (apiResponse?.success === 1 && apiResponse?.country_code) {
      let selectedCountry = countryList.find(
        (item: any) => item?.iso_code_2 === apiResponse.country_code
      );
      if (!selectedCountry) {
        selectedCountry = countryList[0];
      }
      if (
        sessionStorage.getItem("ipCountryCode") &&
        sessionStorage.getItem("ipCountryCode") ===
          selectedCountry.iso_code_2 &&
        sessionStorage.getItem("userCountryCode") &&
        sessionStorage.getItem("userCountryCode") !== selectedCountry.iso_code_2
      ) {
        selectedCountry = countryList.find(
          (item: any) =>
            item.iso_code_2 === sessionStorage.getItem("userCountryCode")
        );
        if (!selectedCountry) {
          selectedCountry = countryList.find(
            (item: any) =>
              item.iso_code_2 === sessionStorage.getItem("ipCountryCode")
          );
          sessionStorage.setItem("userCountryCode", "");
        }
      } else {
        sessionStorage.setItem("ipCountryCode", selectedCountry.iso_code_2);
      }

      setUserCountry(selectedCountry.country_id);
      setSelectedCountry(selectedCountry);
      setUserCountryCode(selectedCountry.iso_code_2);
      setOriginCountryCode(apiResponse.country_code);
    } else {
      const selectedCountry = countryList[0];
      setUserCountry(selectedCountry.country_id);
      setSelectedCountry(selectedCountry);
      setUserCountryCode(selectedCountry.iso_code_2);
    }
  }

  function getFormattedCountryData(voucherCountryList: any = []) {
    const countryListSort = voucherCountryList.sort((a: any, b: any) => {
      a = a.filter_value.toLowerCase();
      b = b.filter_value.toLowerCase();
      return a < b ? -1 : a > b ? 1 : 0;
    });
    const countryList = countryListSort.map((item: any) => {
      item.country_id = item.filter_value_id;
      item.name = item.filter_value;
      delete item.filter_value;
      delete item.filter_value_id;
      return item;
    });
    return countryList;
  }

  function fetchAppData(routeList: any, custom_url: string = "") {
    let url_following = window.location.pathname.toLowerCase().split("/");

    if (url_following[url_following.length - 1] === "") {
      url_following.pop();
    }
    const {
      setDefaultCountryList,
      setVoucherCategoryList,
      setExperienceCategoryList,
      setVoucherCountryList,
      setMerchandiseCategoryList,
      setMerchCountryList,
      appInfo: {
        userCountryCode = "",
        countryList = [],
        selectedCountry: { iso_code_2 = "IN" } = {},
      } = {},
    }: any = props;

    const voucherCountry = countryList.find(
      (x: any) => x.iso_code_2 === userCountryCode
    );

    const filterVoucherParams = {
      url: custom_url,
      filter_group_id: "41",
    };
    setVoucherCountryList([]);
    const getCountryIsoCode2 = ApiService.getCountryIsoCode2();
    const getCategoryList = ApiService.getUrlBasedVoucher(
      custom_url,
      (voucherCountry && voucherCountry.country_id) || "",
      isLogin
    );
    const getMerchandiseCatList = ApiService.getMerchandiseCategoriesList(
      custom_url,
      (voucherCountry && voucherCountry.country_id) || "",
      isLogin
    );
    const promiseArray = [
      Promise.resolve(null),
      Promise.resolve(null),
      Promise.resolve(null),
      getCountryIsoCode2,
      getCategoryList,
      Promise.resolve(null),
      Promise.resolve(null),
    ];
    if (routeList.includes(ROUTE_NAME.MERCHANDISES)) {
      promiseArray[5] = ApiService.getMerchandiseCategoriesList(
        custom_url,
        (voucherCountry && voucherCountry.country_id) || "",
        isLogin
      );
    }
    if (routeList.includes(ROUTE_NAME.EXPERIENCES)) {
      promiseArray[2] = ApiService.getExperiencesCategoriesList(iso_code_2);
    }
    if (routeList.includes(ROUTE_NAME.VOUCHERS)) {
      promiseArray[1] = ApiService.fetchVoucherCountries(
        { filtersParams: JSON.stringify(filterVoucherParams) },
        isLogin
      );
    }
    if (routeList.includes(ROUTE_NAME.MERCHANDISES)) {
      promiseArray[6] = ApiService.fetchVoucherCountries(
        {
          filtersParams: JSON.stringify({
            ...filterVoucherParams,
            merchandise: true,
          }),
        },
        isLogin
      );
    }

    Promise.all(promiseArray)
      .then((countryPromiseResponse) => {
        const voucherResponse = countryPromiseResponse[1];
        const experienceCatList = countryPromiseResponse[2];
        const countryOrigindata = countryPromiseResponse[3];
        const categoryListData = countryPromiseResponse[4];
        const merchandiseCatList = countryPromiseResponse[5];
        const merchResponse = countryPromiseResponse[6];

        let defaultCountrylist = [];
        if (
          experienceCatList &&
          experienceCatList.getExperiencesCategoriesList &&
          experienceCatList.getExperiencesCategoriesList.categories
        ) {
          const newInitialCategories =
            experienceCatList.getExperiencesCategoriesList.categories.map(
              (category: string) => _startCase(category?.toLowerCase())
            );
          setExperienceCategoryList(newInitialCategories || []);
        } else {
          setExperienceCategoryList([]);
        }

        if (merchandiseCatList?.data?.fetchVouchersFilters?.filters) {
          const newInitialCategories =
            merchandiseCatList?.data?.fetchVouchersFilters?.filters.map(
              (category: any) => ({
                name: _startCase(category?.filter_value?.toLowerCase()),
                value: category?.filter_value_id?.toString(),
              })
            );
          setMerchandiseCategoryList(newInitialCategories || []);
        } else {
          setMerchandiseCategoryList([]);
        }

        if (
          routeList.includes(ROUTE_NAME.VOUCHERS) === true &&
          voucherResponse &&
          voucherResponse.data &&
          voucherResponse.data.fetchVouchersFilters &&
          voucherResponse.data.fetchVouchersFilters.Error == false
        ) {
          setVoucherCountryList(
            getFormattedCountryData(
              voucherResponse.data.fetchVouchersFilters.filters
            )
          );
          if (
            !routeList.includes(ROUTE_NAME.EXPERIENCES) ||
            !window.location.pathname
              .toString()
              .includes(ROUTE_NAME.EXPERIENCES)
          ) {
            defaultCountrylist =
              voucherResponse.data.fetchVouchersFilters.filters;
            // setDefaultCountryList(defaultCountrylist);
          }
        }

        if (
          routeList.includes(ROUTE_NAME.MERCHANDISES) === true &&
          merchResponse &&
          merchResponse.data &&
          merchResponse.data.fetchVouchersFilters &&
          merchResponse.data.fetchVouchersFilters.Error == false
        ) {
          setMerchCountryList(
            getFormattedCountryData(
              merchResponse.data.fetchVouchersFilters.filters
            )
          );
        }

        if (
          categoryListData &&
          categoryListData.data &&
          categoryListData.data.fetchVouchersFilters &&
          categoryListData.data.fetchVouchersFilters.filters
        ) {
          const output = categoryListData.data.fetchVouchersFilters.filters;
          setVoucherCategoryList(output);
        }

        setUserCountryFromIp(countryOrigindata, defaultCountrylist);
      })
      .catch((error) => {})
      .finally(() => {});
  }

  function doLoginCheck() {
    const token = getCookieToken(LOGIN_COOKIE_NAME);
    if (Number(token) === 1) {
      props.setLoginStatus(true);
    } else if (isLoggedIn) {
      props.resetUserData();
      props.resetCart();
    }
  }

  // Gets userdata for redirecting to company's marketplace
  function fetchUserDetails(initialCustomUrl: string) {
    setIsUserLoading(true);
    ApiService.getUserDetails()
      .then((data: any) => {
        props.setUserDetails(data && data.fetchUserDetails);

        const email = data?.fetchUserDetails?.email || "";

        if (email) {
          setGlobalCookie("LOGIN_EMAIL", btoa(email));
        }

        const domain = data?.fetchUserDetails?.domain;
        let custom_url = "";
        custom_url = domain ? `/${domain}` : "/marketplace/vouchers";
        getCustomUrl(custom_url, true);
      })
      .catch((_err) => {
        // this.handleUserDetailsFetchFailure();
        setIsUserLoading(false);
      })
      .finally(() => {
        // this.setState({ isFetchingUser: false });
        setIsUserLoading(false);
      });
  }

  function fetchEmpulsePoints() {
    ApiService.getUserPoints(1)
      .then((data: any) => {
        const { getPoints } = data;
        if (getPoints?.success === 1 && getPoints?.data) {
          const { data: pointsData } = getPoints;
          const userPoints = {
            availablePoints:
              (pointsData?.RP &&
                pointsData?.RP[0] &&
                pointsData.RP[0].balance) ||
              0,
            redeemedPoints:
              (pointsData.RMP &&
                pointsData.RMP[0] &&
                pointsData.RMP[0].balance) ||
              0,
            conversionFactor: pointsData?.conversion_factor || 1,
            currencyCode: pointsData?.currency_code || "INR",
            platform: pointsData?.platform || "",
            currency: pointsData?.currency || {},
            integrationName: pointsData?.label || "Points",
            isThirdPartyUser: pointsData?.isThirdPartyUser,
            pointsConversionFactor: pointsData?.pointsConversionFactor || 1,
          };
          props.setUserPoints(userPoints);
        }
      })
      .catch((err) => {});
  }

  function getGCB() {
    ApiService.getGCB().then((data: any) => {
      if (data?.data?.fetchGcb?.success === 1) {
        data = data?.data?.fetchGcb?.data;
        props.setGCB({
          availableGCB: data?.total || 0,
          voucherList: data?.voucher_lists || [],
          currencyCode: data?.currency_code || "",
        });
      } else {
        props.setGCB({
          availableGCB: 0,
          voucherList: [],
          currencyCode: "",
        });
      }
    });
  }

  function initializeWeglotApi() {
    const headEl = document.getElementsByTagName("head")[0];
    const scriptTag = document.createElement("script");
    scriptTag.innerHTML = `Weglot.initialize({
      api_key: "wg_fae9f07765496a53dbbaf34855be5d376",
      switchers: [
        {
          // Same as button_style at root
          button_style: {
            full_name: true,
            with_name: true,
            is_dropdown: true,
            with_flags: true,
            flag_type: "circle",
            invert_flags: false,
          },
          // Move switcher somewhere in the page
          location: {
            target: ".lngSwitch",
            sibling: null,
          },
        },
      ],
      hide_switcher: true,
    });`;
    headEl.appendChild(scriptTag);
  }

  function getMenu() {
    props.setUserJourneyState("points");
    ApiService.getMenu()
      .then((data: any) => {
        if (data?.data?.getMenu?.data?.companyDetails || {}) {
          props.setUserCompanyDetails(data.data.getMenu.data.companyDetails);
        }
        const showMultiLanguage =
          data?.data?.getMenu?.data?.showMultiLanguage || false;
        if (showMultiLanguage) {
          initializeWeglotApi();
        }
        props.setShowMultiLanguage(showMultiLanguage);
        if (data?.data?.getMenu?.data?.showAlternateEmail || false) {
          props.setShowAlternateEmail(
            data.data.getMenu.data.showAlternateEmail
          );
        } else {
          props.setShowAlternateEmail(false);
        }
        if (data?.data?.getMenu?.data?.disablePhoneNumberInCheckout || false) {
          props.setPhnNumDisableData(
            data.data.getMenu.data.disablePhoneNumberInCheckout
          );
        } else {
          props.setPhnNumDisableData(false);
        }
        if ((data?.data?.getMenu?.success || 0) === 1) {
          props.setIsAdmin(true);
        } else {
          props.setIsAdmin(false);
        }
      })
      .catch((err) => {});
  }

  function getXoxoTokens() {
    ApiService.getXoxoTokens().then((data: any) => {
      if (data) {
        if (!data.total) {
          data.total = 0;
        }
        props.setXoxoTokens(data);
      }
    });
  }

  function getAccounts() {
    ApiService.handleFetchAccounts()
      .then((data: any) => {
        if (data) {
          props.setLinkedAccounts(data);
        }
      })
      .catch((err) => {});
  }

  useEffect(() => {
    const sap_wallet_id = new URLSearchParams(window.location.search).get(
      "sap_wallet_id"
    );

    if (sap_wallet_id) {
      sessionStorage.setItem("sap_wallet_id", sap_wallet_id);
    }

    let token = getCookieToken(LOGIN_COOKIE_NAME);

    doLoginCheck();

    const { branding: { custom_url = "/" } = {} }: any = props;
    if (Number(token) === 1) {
      fetchUserDetails(custom_url);
      getMenu();
      fetchEmpulsePoints();
      getGCB();
      getXoxoTokens();
      getAccounts();
    } else {
      props.setRoutes(routeList);
      getCustomUrl("/");
    }

    if (isLoggedIn) {
      const cartJson = {
        count: 0,
        url: "/",
      };
      props.fetchCartWithThunk({
        cartfetch: JSON.stringify(cartJson),
      });
    }
  }, []);

  useInterval(() => {
    let token = getCookieToken(LOGIN_COOKIE_NAME);
    if (Number(token) !== 1 && isLoggedIn) {
      props.resetCart();
      props.resetUserData();
      props.setLoginStatus(false);

      const { custom_url = "/" } = props.branding;
      window.location.replace(custom_url + window.location.search);
    }
    if (Number(token) === 1 && !isLoggedIn) {
      doLoginCheck();
    }
  }, 5000);

  return (
    <>
      {isLoading || (isLoggedIn && isUserLoading) ? (
        <PageLoader />
      ) : (
        props.children
      )}
    </>
  );
};

const mapStateToProps = ({ user, appInfo }: StoreState) => ({
  userDetails: getUserDetails(user),
  isLoggedIn: getLoginStatus(user),
  branding: getBranding(appInfo),
  routes: getRoutes(appInfo),
  platFormSettings: getPlatFormSettings(appInfo),
  userPoints: getUserPoints(user),
  countryList: appInfo.countryList,
  userCountry: appInfo.userCountry,
  originCountryCode: appInfo.originCountryCode,
  appInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setLoginStatus: (isLoggedIn: any) => setLoginStatus(isLoggedIn),
      setUserDetails: (userDetails: any) => setUserDetails(userDetails),
      setBranding: (branding: any) => setBranding(branding),
      setShowCts: (showCts: boolean) => setShowCts(showCts),
      setRoutes: (routes: any) => setRoutes(routes),
      setUserPoints: (userPoints: Points) => setUserPoints(userPoints),
      setGCB: (gcbObgect: GCB) => setGCB(gcbObgect),
      resetUserData: () => resetUserData(),
      setIsAdmin: (isAdmin: boolean) => setIsAdmin(isAdmin),
      setUserCountry: (countryId: number) => setUserCountry(countryId),
      setUserCountryCode: (countryCode: string) =>
        setUserCountryCode(countryCode),
      setSelectedCountry: (selectedCountry: any) =>
        setSelectedCountry(selectedCountry),
      setOriginCountryCode: (isoCode: any) => setOriginCountryCode(isoCode),
      setVoucherCountryList: (countryList: any) =>
        setVoucherCountryList(countryList),
      setMerchCountryList: (countryList: any) =>
        setMerchCountryList(countryList),
      setDefaultCountryList: (countryList: any) => setCountryList(countryList),
      setXoxoTokens: (tokens: Tokens) => setXoxoTokens(tokens),
      setUserCompanyDetails: (company: CompanyDetails) =>
        setUserCompany(company),
      setPlatformSettings: (platformSettings: any) =>
        setPlatformSettings(platformSettings),
      setVoucherCategoryList: (voucherCategoryList: any) =>
        setVoucherCategoryList(voucherCategoryList),
      setExperienceCategoryList: (experienceCategoryList: any) =>
        setExperienceCategoryList(experienceCategoryList),
      setMerchandiseCategoryList: (merchandiseCategoryList: any) =>
        setMerchandiseCategoryList(merchandiseCategoryList),
      fetchCartWithThunk: (variables: any, specialCategory: boolean) =>
        fetchCartWithThunk(variables),
      resetCart: () => resetCart(),
      setShowAlternateEmail: (showAlternateEmail: boolean) =>
        setShowAlternateEmail(showAlternateEmail),
      setPhnNumDisableData: (disablePhoneNumberInCheckout: boolean) =>
        setPhnNumDisableData(disablePhoneNumberInCheckout),
      setLinkedAccounts: (accountsData: any) => setLinkedAccounts(accountsData),
      toggleLinkedAccountsModal: (data: any) => toggleLinkedAccountsModal(data),
      setShowMultiLanguage: (showMultiLanguage: boolean) =>
        setShowMultiLanguage(showMultiLanguage),
      setUserJourneyState: (userJourneyUseCase: any) =>
        setUserJourneyState(userJourneyUseCase),
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Bootstrap);
