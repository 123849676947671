import React from "react";
import { Modal, ModalHeader, Row, Col } from "reactstrap";
import MediaQuery from "react-responsive";
import { Icon, Typography } from "@plum-xoxo/stores-dsm";

import VoucherDetails from "../Header/Components/ActionHeader/VoucherDetails";
import { getColorFromTheme } from "../../../utils/helpers";

interface Props {
  showmodal: Function;
}

class XoxoValidityModal extends React.Component<Props> {
  toggle = () => {
    this.props.showmodal();
  };

  render() {
    return (
      <Modal isOpen={true} toggle={this.toggle} className="validityModal">
        <MediaQuery minDeviceWidth={992}>
          <ModalHeader
            toggle={this.toggle}
            charCode={
              <Icon
                iconName="Close"
                size={20}
                fill={getColorFromTheme("grey", "900")}
              />
            }
          >
            <Typography textType="body" fontWeight="medium" size="M">
              Xoxo Codes Balance Checker
            </Typography>
          </ModalHeader>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={992}>
          <ModalHeader>
            <Row>
              <Col sm={12}>
                <img
                  onClick={() => this.toggle()}
                  className="text-start"
                  alt="icon"
                  src="/img/icons/modal-cross.svg"
                />
              </Col>
            </Row>
          </ModalHeader>
        </MediaQuery>
        <VoucherDetails showmodal={this.props.showmodal} />
      </Modal>
    );
  }
}
export default XoxoValidityModal;
