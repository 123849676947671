import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BrandLogo from "../Components/BrandLogo";
import { bindActionCreators, Dispatch } from "redux";
import cx from "classnames";
import HomePage from "../../Modal/HomePage";
import "./header.scss";
import Search from "../Components/Search";
import { Icon, Typography, Button } from "@plum-xoxo/stores-dsm";
import { Tooltip, UncontrolledTooltip } from "reactstrap";
import PlumLink from "../../../../commons/components/Elements/PlumLink";
import XoxoValidityModal from "../../../../commons/components/Modal/XoxoValidityModal";
import LanguageSelector from "../Components/LanguageSelector";
import SelectCountry from "../Components/CountrySelection";
import HeaderMenuDropdown from "../Components/HeaderMenuDropdown";
import { SingleDot, Star, User } from "../../../../commons/components/Icons";
import { setOrderType } from "../../../../store/order/actions";
import {
  amountConversion,
  capitalizeEachWord,
  getColorFromTheme,
  isLengthyArray,
} from "../../../../utils/helpers";
import { StoreState } from "../../../../store/store.state";
import { getLoginStatus, getTokens } from "../../../../store/user/getters";
import { Tokens } from "../../../../store/user/types";
import {
  getPlatFormSettings,
  getUserJourneyUseCase,
} from "../../../../store/appInfo/getters";
import {
  setCategoryId,
  setUserJourneyState,
} from "../../../../store/appInfo/actions";
import Badges from "../../../../commons/components/Badges";
import ApiService from "../../../../api/api.service";
import { toast } from "react-toastify";
import CheckForPerks from "../../../../hocs/CheckForPerks";
import PerksLoadingModal from "../../../../commons/components/Modal/PerksLoadingModal";
import { useHistory, useLocation } from "react-router-dom";
import {
  StyledHeaderNavigation,
  StyledHeaderTop,
  StyledPointBox,
} from "./header.styles";
import { Divider } from "../../../../commons/components/Divider";
import { useScrollDirection } from "../../../../commons/hooks/useScrollDirection";
import { getFullName } from "../../../../commons/helpers/user.helpers";
const merchandiseCategoryId = process.env.REACT_APP_GV_MERCHANDISE_CATEGORY_ID;
const loungeCategoryId = process.env.REACT_APP_GV_LOUNGE_CATEGORY_ID;
const merchandiseCountryCodes = process.env.REACT_APP_MERCHANDISE_COUNTRY_CODES;

interface DesktopHeaderProps {
  userPoints?: number;
  routes: any;
  branding?: any;
  paymentHeader?: boolean;
  voucherCategoryList: object[];
  merchandiseCategoryList: any;
  experienceCategoryList: any;
  fixedHeader: boolean;
  withoutContainer?: boolean;
  setOrderType?: (orderType: string) => void;
  countryList: any;
  isLoggedIn: Boolean;
  user: any;
  isAdmin?: Boolean;
  adminPanelAccess?: any;
  linkedAccountsCount: number;
  showMultiLanguage?: Boolean;
  logOut: any;
  cartItemsCount?: any;
  Tokens?: Tokens;
  userJourneyUseCase: any;
  setUserJourneyState: any;
  platformSettings?: any;
  rewardsApiPage?: boolean;
  setCategoryId: Function;
  voucherCountryList: any;
  userCountryCode: any;
  isLoogedIn: any;
  custom_url?: string;
  categoryId?: number;
  appInfo: any;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  routes,
  branding,
  paymentHeader,
  voucherCategoryList,
  merchandiseCategoryList,
  experienceCategoryList,
  fixedHeader,
  withoutContainer = false,
  setOrderType,
  isLoggedIn,
  user,
  isAdmin,
  adminPanelAccess,
  linkedAccountsCount,
  showMultiLanguage,
  logOut,
  cartItemsCount,
  Tokens,
  userJourneyUseCase = "",
  setUserJourneyState,
  platformSettings = {},
  rewardsApiPage = false,
  setCategoryId,
  voucherCountryList = [],
  custom_url,
  categoryId,
  appInfo,
}) => {
  const [modal, setModal] = useState(false);
  const [showVoucherBalModal, toggleVoucherBalModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTip = () => setTooltipOpen(!tooltipOpen);
  const [isPerksRedirecting, setPerksRedirectLoading] = useState(false);
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const categroy_id_param = searchParams.get("category_id");
  const userCountryCode = appInfo?.userCountryCode;

  const scrollDirection = useScrollDirection();

  useEffect(() => {
    const redeem = new URLSearchParams(window.location.search).get("redeem");
    if (redeem === "code") {
      setUserJourneyState("code");
      toggle();
      setTimeout(() => {
        setTooltipOpen(false);
      }, 4000);
    }
    const pts = new URLSearchParams(window.location.search);
    pts.delete("redeem");
  }, []);

  const handleMerchandiseClick = () => {
    setCategoryId(merchandiseCategoryId);
    history.push(`${custom_url}/merchandises`);
  };
  const handleLoungeClick = () => {
    setCategoryId(loungeCategoryId);
    history.push("?category_id=" + loungeCategoryId);
  };

  if (paymentHeader) {
    return (
      <div className="fixed-header">
        <div
          className={`${
            fixedHeader ? "fixed-top bg-white" : ""
          } header container-fluid payment-header px-4 py-3`}
        >
          <div className={!withoutContainer ? "container" : ""}>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ height: "40px" }}>
                <BrandLogo custom_url={branding.custom_url} />
              </div>
              {rewardsApiPage ? (
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <Typography
                      textType="body"
                      size="M"
                      color={getColorFromTheme("grey", "800")}
                      className="me-2"
                    >
                      Want a detailed overview?
                    </Typography>
                    <Icon iconName="ArrowRight" size="20" />
                  </div>

                  <Button
                    onClick={() =>
                      window.open(
                        "https://www.xoxoday.com/book-a-demo?utm_source=xoxostore&utm_medium=api-catalog",
                        "_blank"
                      )
                    }
                    className="ms-4"
                  >
                    <Typography
                      textType="body"
                      size="M"
                      color={getColorFromTheme("white", "900")}
                    >
                      Contact Us
                    </Typography>
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const CartSelector = () => {
    return (
      <PlumLink url="/cart" onClick={() => setOrderType!("VOUCHERS")}>
        <Button color="text">
          <div className="d-flex align-items-center">
            <span className="position-relative">
              {cartItemsCount > 0 && (
                <span className="cart-count">
                  {cartItemsCount > 9 ? "9+" : cartItemsCount}
                </span>
              )}
              <Icon
                iconName="ShoppingBag"
                size={20}
                fill={getColorFromTheme("grey", "800")}
              />
            </span>
            <span className="ms-2">
              <Typography
                textType="body"
                size="M"
                color={getColorFromTheme("grey", "800")}
              >
                Cart
              </Typography>
            </span>
          </div>
        </Button>
      </PlumLink>
    );
  };

  const HelpSection = () => {
    return (
      <PlumLink type="ANCHOR_EXT" href="https://www.xoxoday.com/support">
        <Button color="text">
          <div className="d-flex align-items-center">
            <Icon
              iconName="InfoCircle"
              size={20}
              fill={getColorFromTheme("grey", "800")}
            />
            <span className="ms-2">
              <Typography
                textType="body"
                size="M"
                color={getColorFromTheme("grey", "800")}
              >
                Help
              </Typography>
            </span>
          </div>
        </Button>
      </PlumLink>
    );
  };

  const LoginSelector = () => {
    return (
      <PlumLink url="/login">
        <Button
          endIcon={
            <Icon
              iconName="MyProfile"
              fill={getColorFromTheme("white", "900")}
            />
          }
        >
          Sign In
        </Button>
      </PlumLink>
    );
  };

  const PointsView = () => {
    const {
      customText: { empulsPoints: empulsPointsText = "Xoxo Points" } = {},
    } = platformSettings;
    const {
      gcb: { availableGCB = 0 } = {},
      userPoints: {
        currency: { pointsBaseConversionValue = 1, code = "INR" } = {},
        availablePoints = 0,
        pointsConversionFactor = 1,
      } = {},
    } = user;
    const tokensTotal = Tokens?.total ? Tokens.total : 0;
    const points = availablePoints;
    const xoxoPoint = amountConversion(
      pointsBaseConversionValue || 1,
      availableGCB,
      1
    );

    const finalPoints = tokensTotal ? tokensTotal : points ? points : xoxoPoint;
    const { displayCatalogInPoints = 0 } = platformSettings;
    return (
      <>
        <UncontrolledTooltip
          placement="bottom-end"
          target="pointsConversionTooltip"
        >
          <Typography
            textType="body"
            size="S"
            color={getColorFromTheme("white", "900")}
          >
            {displayCatalogInPoints && points
              ? `${pointsConversionFactor} Reward Point = 1 ${code}`
              : !tokensTotal
              ? points || displayCatalogInPoints
                ? `${pointsConversionFactor}  Reward Point = 1 ${code}`
                : `1 ${
                    empulsPointsText ? empulsPointsText : "Xoxopoint"
                  } = 1 ${code}`
              : `1 Reward Point = 1 ${code}`}
          </Typography>
        </UncontrolledTooltip>
        <StyledPointBox
          as={PlumLink}
          id="pointsConversionTooltip"
          url={points ? "/reward-redemption" : "/points"}
        >
          <Star />
          {finalPoints ? parseFloat(finalPoints).toFixed(2) : 0}
        </StyledPointBox>
      </>
    );
  };

  const handlePerksSelection = () => {
    setPerksRedirectLoading(true);
    const data = ApiService.generateSsoToken();
    data
      .then((data: any) => {
        if (data?.ssoToken?.success || 0) {
          const landingPage = data.ssoToken.landing_page;
          window.open(landingPage, "_blank");
        } else {
          toast.error("Error in generating SSO token. Please try again");
        }
      })
      .finally(() => setPerksRedirectLoading(false));
  };

  const ProfileSelector = () => {
    const first_name = user?.userDetails?.first_name || "";
    const last_name = user?.userDetails?.last_name || "";
    const user_login_type = user?.userDetails?.user_login_type || 0;
    const profileMenuItems = [
      {
        id: 1,
        name: getFullName(first_name, last_name),
        redirectTo: "/profile",
        iconName: "MyProfile",
        show: true,
      },
      {
        id: 2,
        name: "Orders",
        redirectTo: routes.includes("vouchers")
          ? "/orders"
          : "/experiences/orders",
        iconName: "ReceiptCheck",
        show: true,
      },
      {
        id: 3,
        name: "Reward History",
        redirectTo: "/reward-history",
        iconName: "History",
        show: true,
      },
      {
        id: 5,
        name: "Switch to Admin",
        redirectTo: "/admin",
        iconName: "ShieldTick",
        show: isAdmin && adminPanelAccess,
      },
      {
        id: 6,
        name: "Switch Account",
        redirectTo: "/switch-account",
        iconName: "SwitchHorizontal",
        show: linkedAccountsCount > 1 && user_login_type !== "2",
      },
    ];
    return (
      <>
        <Button color="text">
          <div className="d-flex align-items-center">
            <User />
            <span className="ms-2">
              <Typography variant="body2" color="dark" shade="400">
                Profile
              </Typography>
            </span>
          </div>
        </Button>
        <HeaderMenuDropdown customWidth={242} customClass="py-0">
          <div className="countryPills">
            {profileMenuItems.map((item) => {
              if (item.show) {
                return (
                  <PlumLink
                    type={item.id === 5 ? "ANCHOR_EXT" : null}
                    target={item.id === 5 ? "_blank" : null}
                    href={
                      item.id === 5
                        ? window.location.origin + item.redirectTo
                        : null
                    }
                    url={item.id !== 5 ? item.redirectTo : null}
                    key={item.id}
                  >
                    <div key={item.id} className="cursor-pointer countryPill">
                      <Icon
                        iconName={item.iconName}
                        size={16}
                        fill={getColorFromTheme("grey", "900")}
                      />
                      <Typography
                        textType="body"
                        size="S"
                        className="text-truncate"
                        color={getColorFromTheme("grey", "900")}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </PlumLink>
                );
              }
              return null;
            })}
            <div
              className="cursor-pointer countryPill"
              onClick={() => logOut()}
            >
              <Icon
                iconName="ArrowCircleBroken"
                size={16}
                fill={getColorFromTheme("grey", "900")}
              />
              <Typography
                textType="body"
                size="S"
                color={getColorFromTheme("grey", "900")}
              >
                Logout
              </Typography>
            </div>
          </div>
        </HeaderMenuDropdown>
      </>
    );
  };

  const paths = window.location?.pathname?.split("/");
  const activeRoute =
    paths[paths.length - 1] === ""
      ? paths[paths.length - 2]
      : paths[paths.length - 1];

  const {
    userPoints: { isThirdPartyUser },
  } = user;

  const merchandiseCategories = voucherCategoryList?.filter(
    (item: any) => item?.filter_value_id == merchandiseCategoryId
  );

  const loungeCategories = voucherCategoryList?.filter(
    (item: any) => item?.filter_value_id == loungeCategoryId
  );

  const getMenuStyles = (length: number) => {
    let columns = Math.round(length / 8);
    let styles = {
      display: "grid",
      gridTemplateColumns: `repeat(${columns}, auto)`,
    };
    return styles;
  };

  return (
    <div
      className={`fixed-header ${scrollDirection === "down" ? "down" : "up"}`}
    >
      {modal ? (
        <HomePage
          userJourneyUseCase={userJourneyUseCase}
          toggle={toggle}
          showModal={modal}
        />
      ) : null}
      {isPerksRedirecting ? <PerksLoadingModal /> : null}
      {showVoucherBalModal ? (
        <XoxoValidityModal showmodal={() => toggleVoucherBalModal(false)} />
      ) : null}
      <StyledHeaderTop className="header-top">
        <div className="container justify-contend-end">
          <div className="header-quick-links">
            <PlumLink type="ANCHOR_EXT" href="https://www.xoxoday.com/support">
              Help Center
            </PlumLink>
            {!isThirdPartyUser && (
              <>
                <PlumLink
                  type="ANCHOR_EXT"
                  href="https://www.xoxoday.com/corporate-gift-cards-bulk-purchase"
                >
                  Bulk Orders
                </PlumLink>
                {userJourneyUseCase && !isLoggedIn ? (
                  <PlumLink
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      toggleVoucherBalModal(true);
                    }}
                    color="text"
                  >
                    Check Xoxocode Balance
                  </PlumLink>
                ) : null}
                <PlumLink
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    toggle();
                  }}
                  id="redeem-tooltip"
                >
                  How to Redeem?
                </PlumLink>
                <Tooltip
                  isOpen={tooltipOpen}
                  target="redeem-tooltip"
                  toggle={toggleTip}
                  placement="bottom"
                  className="redeem-tooltip"
                >
                  Click here to know How to <br /> Redeem?
                </Tooltip>
              </>
            )}
            {showMultiLanguage ? (
              <>
                <Divider orientation="v" />
                <div className="menuSelect">
                  <LanguageSelector />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </StyledHeaderTop>
      <div className="headerActions">
        <div className="container h-100">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="logoContainer">
                <BrandLogo custom_url={branding.custom_url} />
              </div>
              <div className="d-flex justify-content-center position-relative">
                <Search searchType="DESKTOP" />
              </div>
            </div>
            <div className="action-items">
              <div className="d-flex header-actions-items justify-content-end">
                <div className="menuSelect">
                  <SelectCountry history={history} />
                </div>
                {isLoggedIn ? (
                  <div className="menuSelect">
                    <ProfileSelector />
                  </div>
                ) : null}
                <div className="menuSelect">
                  <CartSelector />
                </div>

                {userJourneyUseCase != "code" ? (
                  <div className="loginBtn ms-3">
                    {!isLoggedIn ? <LoginSelector /> : <PointsView />}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="headerNavigation">
        <div className="container h-100">
          <div className="d-flex justify-content-between align-items-center h-100 position-relative">
            <StyledHeaderNavigation>
              {routes.includes("vouchers") && voucherCategoryList.length && (
                <div className="menuSelect h-100 position-static">
                  <PlumLink
                    url={{
                      pathname: "/vouchers",
                    }}
                    onClick={() => {
                      setCategoryId(null);
                    }}
                    className={cx({
                      active:
                        activeRoute === "vouchers" &&
                        categroy_id_param != merchandiseCategoryId &&
                        categroy_id_param != loungeCategoryId,
                    })}
                  >
                    {activeRoute === "vouchers" &&
                      categroy_id_param != merchandiseCategoryId &&
                      categroy_id_param != loungeCategoryId && (
                        <div className="active-menu" />
                      )}
                    <span>Gift Card </span>
                  </PlumLink>
                  <HeaderMenuDropdown
                    customMinWidth={350}
                    customClass="rewardCategories"
                    top={56}
                  >
                    <div
                      className="countryPills"
                      style={getMenuStyles(voucherCategoryList?.length)}
                    >
                      {isLengthyArray(voucherCategoryList) &&
                        voucherCategoryList.map((item: any, idx: number) => (
                          <PlumLink
                            url={{
                              pathname: `/vouchers/?category_id=${item.filter_value_id}`,
                              state: { item },
                            }}
                          >
                            <div
                              key={idx}
                              className="cursor-pointer countryPill px-4"
                              onClick={() =>
                                setCategoryId(item.filter_value_id?.toString())
                              }
                            >
                              <Typography textType="body" size="S">
                                {capitalizeEachWord(
                                  item.filter_value
                                    .replace("&amp;", "&")
                                    .replace("_", " ")
                                )}
                              </Typography>
                            </div>
                          </PlumLink>
                        ))}
                    </div>
                  </HeaderMenuDropdown>
                </div>
              )}

              {routes.includes("merchandises") &&
                merchandiseCategoryList?.length > 0 &&
                merchandiseCountryCodes
                  ?.split(",")
                  ?.includes(userCountryCode) && (
                  <div className="menuSelect cursor-pointer h-100 ms-4 position-static">
                    <PlumLink
                      url={{
                        pathname: `/merchandises`,
                      }}
                      className={cx({
                        active: activeRoute === "merchandises",
                      })}
                    >
                      {activeRoute === "merchandises" && (
                        <div className="active-menu" />
                      )}
                      <span>Merchandise </span>
                    </PlumLink>
                    {isLengthyArray(merchandiseCategoryList) ? (
                      <HeaderMenuDropdown
                        customMinWidth={350}
                        customClass="rewardCategories"
                        top={56}
                      >
                        <div
                          className="countryPills"
                          style={getMenuStyles(merchandiseCategoryList?.length)}
                        >
                          {merchandiseCategoryList.map(
                            (item: any, idx: number) => {
                              return (
                                <PlumLink
                                  type="ANCHOR"
                                  url={{
                                    pathname: `/merchandises/?filter_value_id=${item?.value}`,
                                    state: { item },
                                  }}
                                >
                                  <div
                                    key={idx}
                                    className="cursor-pointer countryPill px-4"
                                  >
                                    <Typography textType="body" size="S">
                                      {capitalizeEachWord(
                                        item?.name
                                          ?.replace("&amp;", "&")
                                          .replace("_", " ")
                                      )}
                                    </Typography>
                                  </div>
                                </PlumLink>
                              );
                            }
                          )}
                        </div>
                      </HeaderMenuDropdown>
                    ) : null}
                  </div>
                )}

              {routes.includes("experiences") && (
                <div className="menuSelect cursor-pointer h-100 ms-4 position-static">
                  <PlumLink
                    url={{
                      pathname: "/experiences",
                    }}
                    className={cx({ active: activeRoute === "experiences" })}
                  >
                    {activeRoute === "experiences" && (
                      <div className="active-menu" />
                    )}
                    <span>Experience </span>
                  </PlumLink>
                  {isLengthyArray(experienceCategoryList) ? (
                    <HeaderMenuDropdown
                      customMinWidth={350}
                      customClass="rewardCategories"
                      top={56}
                    >
                      <div
                        className="countryPills"
                        style={getMenuStyles(experienceCategoryList?.length)}
                      >
                        {experienceCategoryList.map(
                          (item: any, idx: number) => {
                            return (
                              <PlumLink
                                type="ANCHOR"
                                url={{
                                  pathname: `/experiences/?categories[]=${item}`,
                                  state: { item },
                                }}
                              >
                                <div
                                  key={idx}
                                  className="cursor-pointer countryPill px-4"
                                >
                                  <Typography textType="body" size="S">
                                    {capitalizeEachWord(item || "")}
                                  </Typography>
                                </div>
                              </PlumLink>
                            );
                          }
                        )}
                      </div>
                    </HeaderMenuDropdown>
                  ) : null}
                </div>
              )}

              {routes.includes("vouchers") &&
                loungeCategories?.length > 0 &&
                userCountryCode == "IN" && (
                  <div className="menuSelect cursor-pointer h-100 ms-4">
                    {activeRoute === "vouchers" &&
                      categroy_id_param == loungeCategoryId && (
                        <div className="active-menu" />
                      )}
                    <PlumLink
                      url={{
                        pathname: `/vouchers?category_id=${loungeCategoryId}`,
                      }}
                      className={cx({
                        active:
                          activeRoute === "vouchers" &&
                          categroy_id_param == loungeCategoryId,
                      })}
                      onClick={handleLoungeClick}
                    >
                      <span>Lounge </span>
                    </PlumLink>
                  </div>
                )}

              <CheckForPerks>
                <div className="menuSelect cursor-pointer h-100 ms-4 position-static">
                  <div
                    id="perks-desktop"
                    className="d-flex justify-content-start align-items-center"
                  >
                    <PlumLink
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        handlePerksSelection();
                      }}
                      className="d-flex align-items-center"
                    >
                      <span>Cashback & Offers</span>
                      <div>
                        <Badges
                          customClass="shimmer-effect"
                          textColor={getColorFromTheme("white", "900")}
                          backgroundColor={getColorFromTheme("green", "400")}
                        >
                          <Typography
                            textType="body"
                            fontWeight="semiBold"
                            size="XXS"
                          >
                            New
                          </Typography>
                        </Badges>
                      </div>
                    </PlumLink>
                  </div>
                </div>
              </CheckForPerks>
            </StyledHeaderNavigation>
            <div className="d-flex align-items-center rightSection"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, appInfo }: StoreState) => ({
  user,
  Tokens: getTokens(user),
  userJourneyUseCase: getUserJourneyUseCase(appInfo),
  platformSettings: getPlatFormSettings(appInfo),
  countryList: appInfo.countryList,
  categoryId: appInfo.categoryId,
  custom_url: appInfo?.branding?.custom_url,
  appInfo,
  isLoggedIn: getLoginStatus(user),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setOrderType: (orderType: string) => setOrderType(orderType),
      setUserJourneyState: (userJourneyUseCase: any) =>
        setUserJourneyState(userJourneyUseCase),
      setCategoryId: (categoryId: any) => setCategoryId(categoryId),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHeader);
