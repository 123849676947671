import { UserActionTypes, Points, GCB, Tokens, CompanyDetails } from "./types";

export enum ActionTypes {
  SET_USER_DETAILS = "SET USER DETAILS",
  SET_LOGIN_STATUS = "SET LOGIN STATUS",
  SET_USER_POINTS = "SET USER POINTS",
  RESET_USER_DATA = "RESET_USER_DATA",
  SET_GCB = "SET GCB",
  SET_AUTH_TOKEN = "SET AUTH TOKEN",
  SET_IS_ADMIN = "SET IS ADMIN",
  SET_XOXO_TOKENS = "SET_XOXO_TOKENS",
  SET_USER_COMPANY = "SET_USER_COMPANY",
}

export const setUserDetails = (userDetails: any): UserActionTypes => ({
  type: ActionTypes.SET_USER_DETAILS,
  payload: { userDetails },
});

export const setLoginStatus = (isLoggedIn: boolean): UserActionTypes => ({
  type: ActionTypes.SET_LOGIN_STATUS,
  payload: { isLoggedIn },
});

export const setUserPoints = (userPoints: Points): UserActionTypes => ({
  type: ActionTypes.SET_USER_POINTS,
  payload: { userPoints },
});

export const resetUserData = (): UserActionTypes => ({
  type: ActionTypes.RESET_USER_DATA,
  payload: {},
});

export const setGCB = (gcbObject: GCB): UserActionTypes => ({
  type: ActionTypes.SET_GCB,
  payload: { gcbObject },
});

export const setAuthToken = (token: string): UserActionTypes => ({
  type: ActionTypes.SET_AUTH_TOKEN,
  payload: { token },
});

export const setIsAdmin = (isAdmin: boolean): UserActionTypes => ({
  type: ActionTypes.SET_IS_ADMIN,
  payload: { isAdmin },
});

export const setXoxoTokens = (tokens: Tokens): UserActionTypes => ({
  type: ActionTypes.SET_XOXO_TOKENS,
  payload: { tokens },
});

export const setUserCompany = (
  companyDetails: CompanyDetails
): UserActionTypes => ({
  type: ActionTypes.SET_USER_COMPANY,
  payload: { companyDetails },
});
