import React from "react";
import { Row } from "reactstrap";
import { Button } from "@plum-xoxo/stores-dsm";
import { Icon, Typography } from "@plum-xoxo/stores-dsm";
import HeaderMenuDropdown from "./HeaderMenuDropdown";
import { getColorFromTheme } from "../../../../utils/helpers";
import PlumLink from "../../../../commons/components/Elements/PlumLink";

interface Props {
  isMobile?: Boolean;
  handleBackButtonClick?: any;
}

interface State {
  languageList: any;
  selectedLang: any;
}

const languageArray = [
  { code: "he", name: "Hebrew" },
  { code: "zh", name: "Chinese (Simplified)" },
  { code: "en", name: "English" },
  { code: "tw", name: "Chinese (Traditional)" },
  { code: "ps", name: "Polish" },
  { code: "de", name: "German" },
  { code: "pt", name: "Portuguese" },
  { code: "es", name: "Spanish" },
  { code: "th", name: "Thai" },
  { code: "ja", name: "Japanese" },
  { code: "it", name: "Italian" },
  { code: "fr", name: "French" },
  { code: "ko", name: "Korean" },
];

class LanguageSelector extends React.Component<Props, State> {
  state = {
    languageList: languageArray,
    selectedLang: "",
  };

  componentDidMount() {
    let selectedLang = localStorage.getItem("wglang")
      ? localStorage.getItem("wglang")
      : "en";
    this.setState({ selectedLang });
  }

  changeLang = (lngTo: string = "") => {
    localStorage.setItem("wglang", lngTo);
    window.location.reload();
  };

  renderCountries = (): React.ReactFragment => {
    const { languageList } = this.state;
    languageList.sort((a: any, b: any) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    return (
      languageList &&
      languageList.sort().map((item: any, index: number) => {
        return (
          <div
            key={index}
            className="countryPill cursor-pointer"
            onClick={() => {
              this.changeLang(item.code);
            }}
          >
            <Typography textType="body" size="M">
              {item.name}
            </Typography>
          </div>
        );
      })
    );
  };

  render() {
    const { isMobile = false } = this.props;
    let selectedLang = localStorage.getItem("wglang")
      ? localStorage.getItem("wglang")
      : "en";
    let displayLang = languageArray.filter((ele) => ele.code == selectedLang);

    return (
      <>
        {isMobile ? (
          <>
            <Row style={{ padding: "0px 50px" }}>{this.renderCountries()}</Row>
          </>
        ) : (
          <>
            <PlumLink className="d-flex align-items-center">
              <Icon
                iconName="Language"
                size={20}
                fill={getColorFromTheme("dark", "400")}
              />
              <span className="ms-2">
                {displayLang && displayLang.length && displayLang[0].name}
              </span>
            </PlumLink>
            <HeaderMenuDropdown
              customWidth={216}
              customClass="pt-0 languageDropdown"
              top={0}
            >
              <div>
                <div
                  className="title d-flex align-items-center"
                  style={{ padding: 12 }}
                >
                  <Icon iconName="Language" size={20} />
                  <span className="ms-2">
                    <Typography textType="body" fontWeight="medium" size="M">
                      Change Language
                    </Typography>
                  </span>
                </div>
                <div className="countryPills">{this.renderCountries()}</div>
              </div>
            </HeaderMenuDropdown>
          </>
        )}
      </>
    );
  }
}

export default LanguageSelector;
