import React, { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { getPalette } from '@plum-xoxo/stores-dsm/core/lib/utils'

interface DividerProps {
  orientation?: 'v' | 'h';
  weight?: number;
  style?: CSSProperties;
}

const orientationStyles: Record<string, ReturnType<typeof css>> = {
  v: css`
    width: ${(props:any) => props.weight}px;
  `,
  h: css`
    height: ${(props:any) => props.weight}px;
  `
}

const StyledDivider = styled.div<DividerProps>`
  background-color: ${(props:any) => getPalette('dark', 200)(props)};
  ${(props: any) => orientationStyles[props.orientation || 'h']}
`

export const Divider : React.FC<DividerProps> = ({orientation, ...props}) : JSX.Element => {
  return(
    <StyledDivider orientation={orientation} {...props} />
  )
}

Divider.defaultProps = {
  orientation: 'h',
  weight: 1,
}