import { HomepageActionTypes } from "./types";

export enum ActionTypes {
  EXPERIENCE_DATA_FETCHING = "EXPERIENCE DATA FETCHING",
  EXPERIENCE_DATA_FETCHED = "EXPERIENCE DATA FETCHED",
  EXPERIENCE_DATA_FETCHING_FAILED = "EXPERIENCE DATA FETCHING FAILED",
  VOUCHERS_DATA_FETCHING = "VOUCHERS DATA FETCHING",
  VOUCHERS_DATA_FETCHED = "VOUCHERS DATA FETCHED",
  VOUCHERS_DATA_FETCHING_FAILED = "VOUCHERS DATA FETCHING FAILED",
  PERKS_DATA_FETCHING = "PERKS DATA FETCHING",
  PERKS_DATA_FETCHED = "PERKS DATA FETCHED",
  PERKS_DATA_FETCHING_FAILED = "PERKS DATA FETCHING FAILED",
}

export const fetchingExperienceData = (): HomepageActionTypes => ({
  type: ActionTypes.EXPERIENCE_DATA_FETCHING,
  payload: {},
});

export const setExperienceData = (data: any): HomepageActionTypes => ({
  type: ActionTypes.EXPERIENCE_DATA_FETCHED,
  payload: { data },
});

export const experienceDataFailure = (error: any): HomepageActionTypes => ({
  type: ActionTypes.EXPERIENCE_DATA_FETCHING_FAILED,
  payload: { error },
});

export const fetchingVouchersData = (): HomepageActionTypes => ({
  type: ActionTypes.VOUCHERS_DATA_FETCHING,
  payload: {},
});

export const setVouchersData = (data: any): HomepageActionTypes => ({
  type: ActionTypes.VOUCHERS_DATA_FETCHED,
  payload: { data },
});

export const vouchersDataFailure = (error: any): HomepageActionTypes => ({
  type: ActionTypes.VOUCHERS_DATA_FETCHING_FAILED,
  payload: { error },
});

export const fetchingPerksData = (): HomepageActionTypes => ({
  type: ActionTypes.PERKS_DATA_FETCHING,
  payload: {},
});

export const setPerksData = (data: any): HomepageActionTypes => ({
  type: ActionTypes.PERKS_DATA_FETCHED,
  payload: { data },
});

export const perksDataFailure = (error: any): HomepageActionTypes => ({
  type: ActionTypes.PERKS_DATA_FETCHING_FAILED,
  payload: { error },
});
