import Loadable from "react-loadable";
import Loader from "../components/Loader/PageLoader";

export const Profile = Loadable({
  loader: () => import("../../pages/profile"),
  loading: Loader,
});

export const Checkout = Loadable({
  loader: () => import("../../pages/checkout"),
  loading: Loader,
});

export const Cart = Loadable({
  loader: () => import("../../pages/cart"),
  loading: Loader,
});
export const Search = Loadable({
  loader: () => import("../components/Header/Components/Search"),
  loading: Loader,
});
export const ExperienceDescription = Loadable({
  loader: () => import("../../pages/experience-description"),
  loading: Loader,
});
export const VoucherDescription = Loadable({
  loader: () => import("../../pages/voucher-description"),
  loading: Loader,
});
export const PageNotFound = Loadable({
  loader: () => import("../../pages/pageNotFound/PageNotFound"),
  loading: Loader,
});

export const UnderMaintenace = Loadable({
  loader: () => import("../../pages/pageNotFound/UnderMaintenance"),
  loading: Loader,
});

export const Home = Loadable({
  loader: () => import("../../pages/home"),
  loading: Loader,
});
export const PageLoader = Loadable({
  loader: () => import("../components/Loader/PageLoader"),
  loading: Loader,
});
export const ExpeienceListing = Loadable({
  loader: () => import("../../pages/experiences"),
  loading: Loader,
});
export const MerchandiseListing = Loadable({
  loader: () => import("../../pages/merchandises"),
  loading: Loader,
});
export const MerchandiseDescription = Loadable({
  loader: () => import("../../pages/merchandise-description"),
  loading: Loader,
});
export const VoucherListing = Loadable({
  loader: () => import("../../pages/vouchers"),
  loading: Loader,
});
export const PerksListing = Loadable({
  loader: () => import("../../pages/perks"),
  loading: Loader,
});

export const PaymentCallback = Loadable({
  loader: () => import("../../pages/checkout/containers/PaymentCallback"),
  loading: Loader,
});
export const RazorpayPaymentCallback = Loadable({
  loader: () =>
    import("../../pages/checkout/containers/RazorpayPaymentCallback"),
  loading: Loader,
});
export const PrimerPaymentCallback = Loadable({
  loader: () => import("../../pages/checkout/containers/PrimerPaymentCallback"),
  loading: Loader,
});
export const PaymentExpCallback = Loadable({
  loader: () => import("../../pages/checkout/containers/PaymentExpCallback"),
  loading: Loader,
});
export const PaymentStatus = Loadable({
  loader: () => import("../../pages/payment-success"),
  loading: Loader,
});
export const PaymentSuccessExternal = Loadable({
  loader: () => import("../../pages/payment-success/PaymentSuccessExternal"),
  loading: Loader,
});

export const CustomLanding = Loadable({
  loader: () => import("../../pages/custom-landing"),
  loading: Loader,
});
export const ResetPassword = Loadable({
  loader: () => import("../../pages/reset-password/index"),
  loading: Loader,
});

export const RewardsApiCatalogue = Loadable({
  loader: () => import("../../pages/rewards-api-catalogue"),
  loading: Loader,
});

export const Signup = Loadable({
  loader: () => import("../../pages/signup"),
  loading: Loader,
});

export const Login = Loadable({
  loader: () => import("../../pages/login"),
  loading: Loader,
});

export const SwitchAccount = Loadable({
  loader: () => import("../../pages/SwitchAccount"),
  loading: Loader,
});

export const UnsubscribeMail = Loadable({
  loader: () => import("../../pages/unsubscribe"),
  loading: Loader,
});

export const MobileMenu = Loadable({
  loader: () => import("../../pages/mobile-menu"),
  loading: Loader,
});

export const MobileProfile = Loadable({
  loader: () => import("../../pages/mobile-profile"),
  loading: Loader,
});

export const RedirectPerks = Loadable({
  loader: () => import("../../pages/redirect-perks"),
  loading: Loader,
});
