import { ExpOrderActionTypes, expOrderDetails } from "./types";

export enum ActionTypes {
  SET_EXP_ORDER_DETAILS = "SET EXP ORDER DETAILS",
}

export const setExpOrderDetails = (
  expOrderDetails: expOrderDetails
): ExpOrderActionTypes => ({
  type: ActionTypes.SET_EXP_ORDER_DETAILS,
  payload: { expOrderDetails },
});
