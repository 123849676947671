import React from "react";
import MediaQuery from "react-responsive";
import PlumLink from "../../../../Elements/PlumLink";
import { Input, Icon } from "@plum-xoxo/stores-dsm";
import Search from "@plum-xoxo/stores-dsm/core/input/Search";
import { getColorFromTheme } from "../../../../../../utils/helpers";

interface SearchPageProps {
  getSearchItems: (event: any) => void;
  searchedText: any; // current typed key
  placeholder: string;
  onFocusHandle: (event: any) => void;
}

class SearchInput extends React.Component<SearchPageProps> {
  render() {
    const { getSearchItems, searchedText, placeholder, onFocusHandle } =
      this.props;

    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={992}>
          <Search
            className="headerSearch"
            placeholder={placeholder}
            placeholders={["Gift cards", "Experiences", "Merchandise"]}
            onChange={getSearchItems}
            value={searchedText}
            onFocus={() => onFocusHandle(true)}
            onBlur={() =>
              setTimeout(() => {
                onFocusHandle(false);
              }, 500)
            }
          />
        </MediaQuery>
        <MediaQuery maxDeviceWidth={991}>
          <PlumLink url="/search">
            <Icon
              iconName="Search"
              fill={getColorFromTheme("grey", "900")}
              size={20}
            />
          </PlumLink>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default SearchInput;
