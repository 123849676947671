import React from "react";
import PlumLink from "../../../../Elements/PlumLink";
import { Icon, Input } from "@plum-xoxo/stores-dsm";
import Search from "@plum-xoxo/stores-dsm/core/input/Search";
import { getColorFromTheme } from "../../../../../../utils/helpers";
interface SearchInputProps {
  getSearchItems: (event: any) => void;
  listingPage: boolean;
  searchedKey: any; //From redux store
  searchedText: any; // current typed key
  placeholder: string;
  clearSearch: Function;
}

const SearchInput: React.FC<SearchInputProps> = ({
  getSearchItems,
  listingPage,
  searchedKey,
  searchedText,
  placeholder,
  clearSearch,
}) => {
  return (
    <div className="py-2 px-3 search-box-top">
      <div className="d-flex align-items-center" style={{ columnGap: 12 }}>
        <PlumLink url="/vouchers">
          <Icon
            iconName="ChevronLeft"
            fill={getColorFromTheme("grey", "800")}
            size={20}
          />
        </PlumLink>
        <div className="w-100">
          <Search
            placeholder={placeholder}
            startIcon={null}
            size="small"
            placeholders={["Gift cards", "Experience", "Merchandise"]}
            onChange={getSearchItems}
            value={listingPage ? searchedKey : searchedText}
          />
        </div>
      </div>
    </div>
  );
};
export default SearchInput;
