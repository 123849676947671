import React from "react";

const PageLoader: React.FC = (props:any) => {
  if(props?.error) {
    console.error(props.error);
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        background: "rgba(0,0,0,0.7)",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        textAlign: "center",
        zIndex: 99999,
      }}
      className="pt-5"
    >
      <svg
        width="100px"
        height="100px"
        style={{ transform: "scale(3)" }}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cy="50" cx="52.3333" fill="#66286B" r="5">
          <animate
            attributeName="cx"
            calcMode="linear"
            values="45;55;45"
            keyTimes="0;0.5;1"
            dur="1"
            begin="-0.5s"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cy="50" cx="47.6667" fill="gainsboro" r="5">
          <animate
            attributeName="cx"
            calcMode="linear"
            values="45;55;45"
            keyTimes="0;0.5;1"
            dur="1"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cy="50" cx="52.3333" fill="#66286B" r="5">
          <animate
            attributeName="cx"
            calcMode="linear"
            values="45;55;45"
            keyTimes="0;0.5;1"
            dur="1"
            begin="-0.5s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="fill-opacity"
            values="0;0;1;1"
            calcMode="discrete"
            keyTimes="0;0.499;0.5;1"
            repeatCount="indefinite"
            dur="1s"
          ></animate>
        </circle>
      </svg>
      <hgroup
        style={{ color: "#fff", width: "70vw", margin: "0 auto" }}
      ></hgroup>
    </div>
  );
};

export default PageLoader;
