import styled from 'styled-components'
import { getTypography, getPalette } from "@plum-xoxo/stores-dsm/core/lib/utils";

export const StyledHeaderTop = styled.div`
  height: 38px;
  padding: 4px 0px;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    justify-content: flex-end;

    .header-quick-links {
      ${props => getTypography('label4')(props)}
      display: flex;
      gap: 20px;
    }
  }
`

export const StyledHeaderNavigation = styled.div`
  a {
    ${props => getTypography('body5')(props)}
    color: ${props => getPalette('dark', '400')(props)};
    position: relative;
    display: block;
    &.active {
      color: ${props => getPalette('primary', '400')(props)};
    }
    span {
      padding: 17px 10px;
      display: block;
    }
  }
  .active-menu {
    height: 2px;
    background-color: ${props => getPalette('primary', '300')(props)};
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`

export const StyledPointBox = styled.div`
  display: flex;
  gap: 4px;
  border-radius: 4px;
  background: ${props => getPalette('dark', '100')(props)};
  ${props => getTypography('body2')(props)}
  color: ${props => getPalette('dark', '400')(props)};
  align-items: center;
  padding: ${props => props.isMobile ? '4px 12px': '8px 12px'};
`