import React from "react";

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M9.47829 2.87256C9.14309 2.22298 8.44261 1.69756 7.58227 1.79048C6.81259 1.87339 5.66991 2.15089 4.89078 3.06089C4.09103 3.99548 3.79279 5.45298 4.40689 7.63673C5.06268 9.96631 6.03648 12.263 7.24706 14.1159C8.44863 15.9555 9.92867 17.428 11.6266 17.9926C13.1281 18.4917 14.2764 18.2834 15.114 17.7351C15.9219 17.2063 16.3654 16.4109 16.5948 15.8601C16.8518 15.2434 16.6825 14.6013 16.3473 14.128L15.1109 12.3846C14.8378 11.9994 14.4446 11.7092 13.9889 11.5564C13.5332 11.4037 13.0391 11.3965 12.5789 11.5359L10.8702 12.0538C10.8163 12.0717 10.7581 12.0739 10.7028 12.0601C10.6476 12.0462 10.5978 12.0169 10.5595 11.9759C9.79888 11.1121 8.9467 9.90756 8.71507 8.68839C8.70774 8.65584 8.71277 8.62181 8.72925 8.59256C8.98065 8.18298 9.41297 7.71881 9.84358 7.31256C10.5729 6.62423 10.8492 5.53006 10.3679 4.59756L9.47829 2.87256Z"
      fill="#E7E9EB"
    />
  </svg>
);

const MailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
  >
    <path
      d="M17.8321 3.3335H4.0803C3.13487 3.3335 2.36992 4.0835 2.36992 5.00016L2.36133 15.0002C2.36133 15.9168 3.13487 16.6668 4.0803 16.6668H17.8321C18.7775 16.6668 19.5511 15.9168 19.5511 15.0002V5.00016C19.5511 4.0835 18.7775 3.3335 17.8321 3.3335ZM17.4883 6.87516L11.4117 10.5585C11.1367 10.7252 10.7757 10.7252 10.5007 10.5585L4.4241 6.87516C4.33791 6.82825 4.26244 6.76488 4.20225 6.68887C4.14206 6.61287 4.09841 6.52581 4.07392 6.43297C4.04944 6.34013 4.04464 6.24344 4.05981 6.14875C4.07498 6.05406 4.10981 5.96334 4.1622 5.88208C4.21458 5.80082 4.28342 5.73071 4.36456 5.67599C4.44569 5.62127 4.53744 5.58309 4.63424 5.56374C4.73104 5.5444 4.83088 5.5443 4.92772 5.56345C5.02456 5.5826 5.11638 5.62061 5.19764 5.67516L10.9562 9.16683L16.7148 5.67516C16.796 5.62061 16.8878 5.5826 16.9847 5.56345C17.0815 5.5443 17.1814 5.5444 17.2782 5.56374C17.375 5.58309 17.4667 5.62127 17.5478 5.67599C17.629 5.73071 17.6978 5.80082 17.7502 5.88208C17.8026 5.96334 17.8374 6.05406 17.8526 6.14875C17.8678 6.24344 17.863 6.34013 17.8385 6.43297C17.814 6.52581 17.7703 6.61287 17.7101 6.68887C17.65 6.76488 17.5745 6.82825 17.4883 6.87516Z"
      fill="#E7E9EB"
    />
  </svg>
);

const MessageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1582_718)">
      <path
        d="M2.83079 9.99951C1.88535 9.99951 1.11182 9.24951 1.11182 8.33284V4.16618C1.11182 3.24951 1.88535 2.49951 2.83079 2.49951H9.70669C10.6521 2.49951 11.4257 3.24951 11.4257 4.16618V8.33284C11.4257 9.24951 10.6521 9.99951 9.70669 9.99951H7.98771V12.4995L5.40925 9.99951H2.83079ZM18.3016 14.9995C19.247 14.9995 20.0205 14.2495 20.0205 13.3328V9.16618C20.0205 8.24951 19.247 7.49951 18.3016 7.49951H13.1446V8.33284C13.1446 10.1662 11.5976 11.6662 9.70669 11.6662V13.3328C9.70669 14.2495 10.4802 14.9995 11.4257 14.9995H13.1446V17.4995L15.7231 14.9995H18.3016Z"
        fill="#E7E9EB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1582_718">
        <rect
          width="20.6277"
          height="20"
          fill="white"
          transform="translate(0.255371)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { PhoneIcon, MailIcon, MessageIcon };
