
export function getUserPointObject(data: any) {
  const { getPoints } = data;
  if (getPoints?.success === 1 && getPoints?.data) {
    const { data: pointsData } = getPoints;
    const userPoints = {
      availablePoints:
        (pointsData?.RP &&
          pointsData?.RP[0] &&
          pointsData.RP[0].balance) ||
        0,
      redeemedPoints:
        (pointsData.RMP &&
          pointsData.RMP[0] &&
          pointsData.RMP[0].balance) ||
        0,
      conversionFactor: pointsData?.conversion_factor || 1,
      currencyCode: pointsData?.currency_code || "INR",
      platform: pointsData?.platform || "",
      currency: pointsData?.currency || {},
      isThirdPartyUser: pointsData?.isThirdPartyUser,
      integrationName: pointsData?.label || "Points",
    };
    return userPoints
  }
  return null
}

export const getFullName = (firstName: string, lastName: string) :string => {
  return [firstName, lastName].join(' ')
}

export const getTpdData = (tpd: any = "") => {
  if (tpd) {
    try {
      tpd = JSON.parse(tpd);
    } catch (err) {
      // console.error("Error while parsing tpd json:", err);
    }
  }
  return tpd;
};
