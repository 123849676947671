import React from "react";
import {
  setGlobalCookie,
  getColorFromTheme,
  isMobile,
} from "../../../utils/helpers";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import MediaQuery from "react-responsive";
import classnames from "classnames";
import PlumLink from "../Elements/PlumLink";
import "./modal.scss";
import { Typography, Icon, Button } from "@plum-xoxo/stores-dsm";
import { getCloudinarySplitOptimize } from "../../../utils/cloudinary_vouchers";

const REWARDS_REDEMPTION_STEPS = {
  1: [
    {
      title: "Step 1",
      desc: "Select the products you like and add them to the cart.",
    },
    { title: "Step 2", desc: "Proceed to checkout." },
    {
      title: "Step 3",
      desc: 'In the payments section, select "Use Xoxo Points". If your cart value is more than the value of your points, you can pay using Card/Netbanking. If your cart value is lesser than the value of your points, you can use the remaining value in the next purchase until expiry.',
    },
  ],
  2: [
    {
      title: "Step 1",
      desc: "Add Products into the Cart and proceed to checkout.",
    },
    {
      title: "Step 2",
      desc: "While checkout, enter your gift code and equivalent value will be reduced from your cart total. You may pay partially via other payment modes if the total value exceeds your gift code value.",
    },
  ],
};

interface props {
  toggle: Function;
  showModal: boolean;
  userJourneyUseCase?: any;
}

class HomePage extends React.Component<props> {
  state = {
    showModal: true,
    step: 1,
    activeTab: "1",
  };

  componentDidMount() {
    const { userJourneyUseCase } = this.props;
    if (userJourneyUseCase === "code") {
      this.setState({
        activeTab: "2",
      });
    }
    setTimeout(() => {
      setGlobalCookie("RETURNING_USER", true);
    }, 4000);
  }

  toggleTab = (tab: string) => {
    this.setState({
      activeTab: tab,
    });
  };

  handleModal = () => {
    this.setState(
      {
        showModal: !this.state.showModal,
        step: 1,
        activeTab: "1",
      },
      () => {
        this.props.toggle();
      }
    );
  };

  handleCheck = () => {
    this.setState({
      step: 2,
    });
  };

  step1Data = (description: string = "") => {
    return (
      <div className="redeemSteps">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}>
            <MediaQuery maxDeviceWidth={991}>
              <Typography
                className="mb-1"
                textType="body"
                fontWeight="medium"
                size="S"
                color={getColorFromTheme("grey", "900")}
              >
                Step 1
              </Typography>
            </MediaQuery>
            <img
              src="https://res.cloudinary.com/dyyjph6kx/image/upload/v1681987811/store/redemptionModalStep.png"
              alt="img"
              style={{
                maxWidth: "310px",
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
            <MediaQuery minDeviceWidth={992}>
              <Typography
                textType="body"
                fontWeight="medium"
                size="S"
                color={getColorFromTheme("grey", "900")}
              >
                Step 1
              </Typography>
            </MediaQuery>

            <Typography
              className="mt-1"
              textType="body"
              size="S"
              color={getColorFromTheme("grey", "900")}
            >
              {description}
            </Typography>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { step, activeTab } = this.state;
    const { userJourneyUseCase } = this.props;
    const isOnlyCodeMode = userJourneyUseCase === "code";
    return (
      <Modal
        isOpen={true}
        toggle={this.handleModal}
        className="Home_Modal redeem-modal"
      >
        <MediaQuery minDeviceWidth={992}>
          <ModalHeader
            toggle={this.handleModal}
            charCode={
              <Icon
                iconName="Close"
                fill={getColorFromTheme("grey", "900")}
                size={20}
                onClick={() => this.handleModal()}
              />
            }
          >
            <Typography
              className="mb-1"
              textType="body"
              fontWeight="semiBold"
              size="L"
              color={getColorFromTheme("grey", "900")}
            >
              {isOnlyCodeMode
                ? "Xoxo Codes Redemption Guide"
                : "Redemption Guide"}
            </Typography>
            <Typography
              textType="body"
              size="S"
              color={getColorFromTheme("grey", "600")}
            >
              {isOnlyCodeMode
                ? "To redeem Xoxo Code follow below guide."
                : "To redeem on xoxoday stores follow the below guides."}
            </Typography>
          </ModalHeader>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={992}>
          <ModalHeader>
            <Button
              color="text"
              onClick={() => this.handleModal()}
              startIcon={
                <Icon
                  iconName="ChevronLeft"
                  size={20}
                  fill={getColorFromTheme("grey", "800")}
                />
              }
            >
              Back
            </Button>
          </ModalHeader>
        </MediaQuery>
        <ModalBody>
          <div className="bg howToRedeem">
            {!isOnlyCodeMode ? (
              <Nav tabs className="tnc border-0 mb-2">
                <NavItem>
                  <NavLink
                    className={`${classnames({
                      active: activeTab === "1",
                    })} ${!isMobile() ? "me-5" : ""}`}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    <Typography
                      textType="body"
                      fontWeight="medium"
                      size={isMobile() ? "XS" : "S"}
                      color={
                        activeTab === "1"
                          ? getColorFromTheme("primary", "300")
                          : getColorFromTheme("grey", "600")
                      }
                    >
                      Xoxo Points Redemption
                    </Typography>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    <Typography
                      textType="body"
                      fontWeight="medium"
                      size={isMobile() ? "XS" : "S"}
                      color={
                        activeTab === "2"
                          ? getColorFromTheme("primary", "300")
                          : getColorFromTheme("grey", "600")
                      }
                    >
                      Xoxo Codes Redemption
                    </Typography>
                  </NavLink>
                </NavItem>
              </Nav>
            ) : null}

            <MediaQuery maxDeviceWidth={991}>
              <Typography
                className="mb-4"
                textType="body"
                colot={getColorFromTheme("grey", "900")}
                fontWeight="semiBold"
                size="L"
              >
                {isOnlyCodeMode
                  ? "Xoxo Codes Redemption Guide"
                  : "Redemption Guide"}
              </Typography>
            </MediaQuery>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {this.step1Data(
                  "Add products to cart and proceed to checkout."
                )}
                <div className="redeemSteps">
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}>
                      <MediaQuery maxDeviceWidth={991}>
                        <Typography
                          className="mb-1"
                          textType="body"
                          fontWeight="medium"
                          size="S"
                          color={getColorFromTheme("grey", "900")}
                        >
                          Step 2
                        </Typography>
                      </MediaQuery>
                      <img
                        src="https://res.cloudinary.com/dyyjph6kx/image/upload/v1681987810/store/xoxoPointRememptionModal.png"
                        alt="img"
                        style={{
                          maxWidth: "310px",
                        }}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                      <MediaQuery minDeviceWidth={992}>
                        <Typography
                          textType="body"
                          fontWeight="medium"
                          size="S"
                          color={getColorFromTheme("grey", "900")}
                        >
                          Step 2
                        </Typography>
                      </MediaQuery>

                      <Typography
                        className="mt-1"
                        textType="body"
                        size="S"
                        component="span"
                        color={getColorFromTheme("grey", "900")}
                      >
                        At checkout,{" "}
                        <Typography
                          textType="body"
                          size="S"
                          fontWeight="medium"
                          component="span"
                          color={getColorFromTheme("grey", "900")}
                        >
                          select your points
                        </Typography>{" "}
                        balance and redeem against the shopping cart value. You
                        can pay additional amount via other payment modes as
                        well.
                      </Typography>
                    </Col>
                  </Row>
                </div>
              </TabPane>
              <TabPane tabId="2">
                {this.step1Data(
                  "Add products to cart and proceed to checkout."
                )}

                <div className="redeemSteps">
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}>
                      <MediaQuery maxDeviceWidth={991}>
                        <Typography
                          textType="body"
                          fontWeight="medium"
                          size="S"
                          color={getColorFromTheme("grey", "900")}
                        >
                          Step 2
                        </Typography>
                      </MediaQuery>
                      <img
                        src="https://res.cloudinary.com/dyyjph6kx/image/upload/v1681987810/store/xoxoCodeRememptionModal.png"
                        alt="img"
                        style={{
                          maxWidth: "310px",
                        }}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                      <MediaQuery minDeviceWidth={992}>
                        <Typography
                          textType="body"
                          fontWeight="medium"
                          size="S"
                          color={getColorFromTheme("grey", "900")}
                        >
                          Step 2
                        </Typography>
                      </MediaQuery>

                      <Typography
                        className="mt-1"
                        textType="body"
                        size="S"
                        component="span"
                        color={getColorFromTheme("grey", "900")}
                      >
                        <Typography
                          textType="body"
                          size="S"
                          fontWeight="medium"
                          component="span"
                          color={getColorFromTheme("grey", "900")}
                        >
                          Enter gift card code
                        </Typography>{" "}
                        and redeem against the shopping cart value{" "}
                        <Typography
                          textType="body"
                          size="S"
                          fontWeight="medium"
                          component="span"
                          color={getColorFromTheme("grey", "900")}
                        >
                          without any login/signup.
                        </Typography>{" "}
                        Pay additional amount via other payment modes as well.
                      </Typography>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            </TabContent>
            <div className={`mt-2 ${!isMobile() ? "text-end" : ""}`}>
              <PlumLink
                type="ANCHOR_EXT"
                href="https://helpcenter.xoxoday.com/docs/how-to-redeem-rewards"
              >
                <Button
                  size="small"
                  color="text"
                  endIcon={
                    <Icon
                      iconName="ExternalLink"
                      fill={getColorFromTheme("primary", "300")}
                      size={20}
                    />
                  }
                  className="p-0"
                >
                  <Typography
                    textType="body"
                    fontWeight="medium"
                    size="M"
                    color={getColorFromTheme("primary", "300")}
                  >
                    Learn more
                  </Typography>
                </Button>
              </PlumLink>
            </div>
          </div>
        </ModalBody>
        <MediaQuery minDeviceWidth={992}>
          <ModalFooter className="d-block">
            <Typography
              textType="body"
              size="S"
              color={getColorFromTheme("grey", "600")}
            >
              If you have any queries, kindly contact us via Email or Whatsapp
            </Typography>
            <div
              className="d-flex align-items-center mt-3"
              style={{ columnGap: 20 }}
            >
              {[
                { icon: "Mail", text: "cs@xoxoday.com" },
                { icon: "Whatsapp", text: "+91 80 6191 5050" },
              ].map((item) => (
                <div className="d-flex align-items-center">
                  <Icon
                    iconName={item.icon}
                    fill={getColorFromTheme("grey", "700")}
                    size={20}
                  />
                  <Typography className="ms-2" textType="body" size="S">
                    {item.text}
                  </Typography>
                </div>
              ))}
            </div>
          </ModalFooter>
        </MediaQuery>
      </Modal>
    );
  }
}

export default HomePage;
