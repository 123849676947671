import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Typography } from "@plum-xoxo/stores-dsm";
import { getColorFromTheme } from "../../../utils/helpers";
import Loader from "../Loader";

const PerksLoadingModal: React.FC = () => (
  <Modal isOpen={true} className="validityModal">
    <ModalHeader className="border-0 shadow-none" style={{ padding: 34 }}>
      <></>
    </ModalHeader>
    <ModalBody
      style={{ padding: "20px 32px" }}
      className="d-flex align-items-center"
    >
      <div className="text-center">
        <Loader minHeight="64px" marginTop="0px" />
        <Typography
          className="mb-2"
          fontWeight="semiBold"
          size="S"
          color={getColorFromTheme("primary", "900")}
        >
          Redirecting To Perks Stores
        </Typography>
        <Typography
          textType="body"
          size="S"
          color={getColorFromTheme("primary", "900")}
        >
          You will be accessing the perk stores using your existing xoxoday
          stores credentials and same will be used to checkout incase your
          purchase anything from the perks store.
        </Typography>
      </div>
    </ModalBody>
    <ModalFooter style={{ padding: 34 }}>
      <></>
    </ModalFooter>
  </Modal>
);

export default PerksLoadingModal;
