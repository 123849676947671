import React from "react";
import ApiService from "../../../../../api/api.service";
import DesktopSearchResults from "./Desktop/SearchResults";
import DesktopSearchInput from "./Desktop/SearchInput";
import MobileSearchResults from "./Mobile/SearchResults";
import MobileSearchInput from "./Mobile/SearchInput";
import { connect } from "react-redux";
import { StoreState } from "../../../../../store/store.state";
import { bindActionCreators, Dispatch } from "redux";
import { setSearchedKey } from "../../../../../store/appInfo/actions";
import { AppInfoState } from "../../../../../store/appInfo/types";
import { getLoginStatus } from "../../../../../store/user/getters";
import { convertToSlug } from "../../../../../utils/helpers";
import { getBranding } from "../../../../../store/appInfo/getters";

interface State {
  searchData: object[];
  isSearching: boolean;
  showSearchResults: boolean;
  searchedText: any;
  isHeaderExpanded: boolean;
}

interface Props {
  setSearchedKey: (searchedKey: any) => void;
  appInfo: AppInfoState;
  isLoggedIn: boolean;
  searchType: string;
  branding?: any;
  selectedCountry: any;
}

class Search extends React.Component<Props, State> {
  customUrl: string;
  listingPage: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      searchData: [],
      isSearching: false,
      showSearchResults: false,
      searchedText: "",
      isHeaderExpanded: false,
    };
    this.customUrl = "/";
    const urlPathname = window.location.pathname;
    this.listingPage =
      urlPathname.includes("vouchers") ||
      urlPathname.includes("perks") ||
      urlPathname.includes("experiences");
  }

  componentWillMount() {
    // document.addEventListener('click', this.handleClick, false);
    if (this.listingPage === false) {
      this.props.setSearchedKey("");
    }
  }

  componentWillUnmount() {
    // document.removeEventListener('click', this.handleClick, false);
    if (this.listingPage === false) {
      this.props.setSearchedKey("");
    }
  }

  onFocusHandle = (isShowResults: boolean) => {
    this.setState({ showSearchResults: isShowResults });
  };

  clearSearch = () => {
    this.setState({
      searchedText: "",
      showSearchResults: false,
      isHeaderExpanded: false,
    });
    this.props.setSearchedKey("");
  };

  getSearchItems = (event: any) => {
    const { appInfo, isLoggedIn, setSearchedKey, branding, selectedCountry } =
      this.props;
    const { iso_code_2 = "IN" } = selectedCountry;
    let searchedKey = event.target.value;
    // setSearchedKey(searchedKey)
    this.setState({ searchedText: event.target.value });
    const urlPathname = window.location.pathname;

    if (searchedKey.length === 0) {
      this.setState({ searchData: [] });
      return;
    }
    if (searchedKey.length < 3) {
      this.setState({ showSearchResults: false });
      return;
    }

    let limitFilter = "all";
    // if (window.location.pathname.includes("vouchers")) {
    //     limitFilter = "vouchers";
    // } else if(window.location.pathname.includes("perks")) {
    //     limitFilter = "perks"
    // } else if(window.location.pathname.includes("experiences")){
    //     limitFilter = "experiences";
    // }

    this.setState({ isSearching: true, showSearchResults: true });
    let experiences = "";
    if (searchedKey) {
      experiences += "query=" + searchedKey.replace(/[%!&.()]/g, "") + "&";
    }
    let countryId = appInfo ? appInfo.userCountry : 99;
    let countryList = appInfo ? appInfo.countryList : [];
    let countryDetails = countryList.find(
      (item: any) => item.country_id === countryId
    );
    let countryName = countryDetails ? countryDetails.name : "";
    let countryIsoCode = appInfo ? appInfo.userCountryCode : "";

    let searchCountryId = countryId;
    if (window.location.pathname.includes("vouchers")) {
      let experienceCountryList = appInfo ? appInfo.experienceCountryList : [];
      let experienceCountryDetail = experienceCountryList.find(
        (item: any) => item.iso_code_2 === countryIsoCode
      );
      searchCountryId = experienceCountryDetail
        ? experienceCountryDetail.country_id
        : countryId;
    }

    let customUrl = branding && branding.custom_url ? branding.custom_url : "/";

    const payload = {
      search_text: JSON.stringify({
        search_key: searchedKey,
        experience: experiences,
        custom_url: customUrl,
        limitFilter: limitFilter,
        country_id: searchCountryId,
        country_name: countryName,
        countryCode: iso_code_2,
      }),
    };

    ApiService.getSearchItems(payload, isLoggedIn).then((data: any) => {
      this.setState({ isSearching: false });
      if (data && data.success === 1) {
        const filterData =
          typeof data.data == "string"
            ? (data.data = JSON.parse(data.data))
            : [];
        this.setState({ searchData: filterData });
      } else {
        this.setState({ searchData: [] });
      }
    });
  };

  updateResultUrl = (searchData: object[]): object[] => {
    let updateSearchUrl = searchData.map((dataItem: any) => {
      dataItem.url = dataItem.url.replace(/[!%&.()]/g, "");
      if (dataItem.url.includes("vouchers/")) {
        dataItem.url =
          "/vouchers/description/" +
          convertToSlug(dataItem.name.trim()) +
          "/" +
          dataItem.product_id;
      } else if (dataItem.url.includes("merchandise/")) {
        dataItem.url =
          "/merchandise/" +
          convertToSlug(dataItem.name.trim()) +
          "/" +
          dataItem.product_id;
      }
      return dataItem;
    });
    return updateSearchUrl;
  };

  render() {
    const { searchData, isSearching, showSearchResults, searchedText } =
      this.state;
    const {
      appInfo: { searchedKey },
      setSearchedKey,
      searchType,
      branding,
    } = this.props;
    const SEARCH_PLACEHOLDER = "Search for reward catalog ...";
    return (
      <React.Fragment>
        {searchType === "MOBILE" ? ( //Mobile version search
          <>
            <MobileSearchInput
              getSearchItems={this.getSearchItems}
              listingPage={this.listingPage}
              searchedKey={searchedKey} //From redux store
              searchedText={searchedText} // current typed key
              placeholder={SEARCH_PLACEHOLDER}
              clearSearch={this.clearSearch}
            />
            {showSearchResults === true ? (
              <MobileSearchResults
                searchData={this.updateResultUrl(searchData)}
                isSearching={isSearching}
                searchkey={searchedText}
                customUrl={this.customUrl}
                setSearchedKey={setSearchedKey}
                branding={branding}
              />
            ) : null}
          </>
        ) : (
          // Default Desktop search version
          <>
            <DesktopSearchInput
              getSearchItems={this.getSearchItems}
              searchedText={searchedText} // current typed key
              placeholder={SEARCH_PLACEHOLDER}
              onFocusHandle={this.onFocusHandle}
            />
            {(showSearchResults === true && searchData.length > 0) ||
            isSearching ? (
              <DesktopSearchResults
                searchData={this.updateResultUrl(searchData)}
                isSearching={isSearching}
                setSearchedKey={setSearchedKey}
              />
            ) : null}
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ appInfo, user }: StoreState) => ({
  appInfo,
  branding: getBranding(appInfo),
  isLoggedIn: getLoginStatus(user),
  selectedCountry: appInfo.selectedCountry,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSearchedKey: (searchedKey: any) => setSearchedKey(searchedKey),
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Search);
