import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";

interface Props {
  msg?: String;
}
const LoaderXs: React.FC<Props> = ({ msg }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal isOpen={true} toggle={toggle}>
        <ModalBody>
          <div className="text-center ">
            <br />
            <span className="spinner-border text-primary"></span>
          </div>
          <br />
          <h3 className="text-center">{msg ? msg : ""}</h3>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LoaderXs;
