import { UserInfoState, UserActionTypes } from "./types";
import { ActionTypes } from "./actions";

const initialState: UserInfoState = {
  userDetails: {},
  isLoggedIn: false,
  userPoints: {
    availablePoints: 0,
    redeemedPoints: 0,
    conversionFactor: 0,
    currencyCode: "",
    platform: "",
    currency: {},
  },
  gcb: {
    availableGCB: 0,
    voucherList: [],
    currencyCode: "",
  },
  xoxoTokens: {
    total: 0,
  },
  auth_token: "",
  isAdmin: false,
  companyDetails: {
    name: "",
  },
};

export const userInfoReducer = (
  state: UserInfoState = initialState,
  action: UserActionTypes
): UserInfoState => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: (payload as any).userDetails,
      };
    case ActionTypes.SET_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: (payload as any).isLoggedIn,
      };

    case ActionTypes.SET_USER_POINTS:
      return {
        ...state,
        userPoints: (payload as any).userPoints,
      };

    case ActionTypes.RESET_USER_DATA:
      return (state = initialState);

    case ActionTypes.SET_GCB:
      return {
        ...state,
        gcb: (payload as any).gcbObject,
      };

    case ActionTypes.SET_AUTH_TOKEN:
      return {
        ...state,
        auth_token: (payload as any).token,
      };
    case ActionTypes.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: (payload as any).isAdmin,
      };

    case ActionTypes.SET_XOXO_TOKENS:
      return {
        ...state,
        xoxoTokens: (payload as any).tokens,
      };

    case ActionTypes.SET_USER_COMPANY:
      return {
        ...state,
        companyDetails: (payload as any).companyDetails,
      };

    default:
      return state;
  }
};
