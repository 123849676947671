import { OrderActionTypes, fieldDetail } from "./types";

export enum ActionTypes {
  SET_ORDER_TYPE = "SET ORDER TYPE",
  SET_USE_EMPULSE_POINTS = "SET USE EMPULSE POINTS",
  SET_ORDER_USER_DETAILS = "SET ORDER USER DETAILS",
  SET_USER_DETAILS = "SET USER DETAILS",
  UPDATE_SELECTED_EXP = "UPDATE SELECTED EXP",
  UPDATE_ORDER_STATE = "UPDATE ORDER STATE",
  RESET_USER_DATA = "RESET_USER_DATA",
  SET_USE_GIFT_CARD_BALANCE = "SET USE GIFT CARD BALANCE",
  SET_USE_XOXO_TOKENS = "SET USE XOXO TOKENS",
  APPLY_VOUCHER = "APPLY VOUCHER",
  REMOVE_APPLIED_VOUCHER = "REMOVE APPLIED VOUCHER",
  CHECKOUT_MOUNTING = "CHECKOUT_MOUNTING",
  SET_ETIHAD_MEMBER_ID = "SET_ETIHAD_MEMBER_ID",
  SET_CHECKOUT_ERROR = "SET_CHECKOUT_ERROR",
}

export const setOrderType = (orderType: string): OrderActionTypes => ({
  type: ActionTypes.SET_ORDER_TYPE,
  payload: { orderType },
});

export const useEmpulsePoints = (usePoints: boolean): OrderActionTypes => ({
  type: ActionTypes.SET_USE_EMPULSE_POINTS,
  payload: { usePoints },
});

export const setOrderUserDetails = (
  fieldDetail: fieldDetail
): OrderActionTypes => ({
  type: ActionTypes.SET_ORDER_USER_DETAILS,
  payload: { fieldDetail },
});

export const updateSelectedExp = (expProduct: any): OrderActionTypes => ({
  type: ActionTypes.UPDATE_SELECTED_EXP,
  payload: { expProduct },
});

export const updateOrderState = (success: boolean): OrderActionTypes => ({
  type: ActionTypes.UPDATE_ORDER_STATE,
  payload: { success },
});

export const useGiftCardBalance = (useGCB: boolean): OrderActionTypes => ({
  type: ActionTypes.SET_USE_GIFT_CARD_BALANCE,
  payload: { useGCB },
});

export const useXoxoTokens = (useTokens: boolean): OrderActionTypes => ({
  type: ActionTypes.SET_USE_XOXO_TOKENS,
  payload: { useTokens },
});

export const setEtihadMemberId = (etihadMemberId: any): OrderActionTypes => ({
  type: ActionTypes.SET_ETIHAD_MEMBER_ID,
  payload: { etihadMemberId },
});

export const applyVoucher = (voucher: any): OrderActionTypes => ({
  type: ActionTypes.APPLY_VOUCHER,
  payload: { voucher },
});

export const removeAppliedVoucher = (index: any): OrderActionTypes => ({
  type: ActionTypes.REMOVE_APPLIED_VOUCHER,
  payload: { index },
});

export const checkoutMounting = (): OrderActionTypes => ({
  type: ActionTypes.CHECKOUT_MOUNTING,
  payload: {},
});

export const setCheckoutErrorState = (errorState: any): OrderActionTypes => ({
  type: ActionTypes.SET_CHECKOUT_ERROR,
  payload: { errorState },
});
