import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { Icon, Typography, Input } from "@plum-xoxo/stores-dsm";
import "../styles.scss";
import { getColorFromTheme } from "../../../../utils/helpers";

interface MobileCountrySelectionProps {
  handleBack?: any;
  showCountryModal?: boolean;
  countryData: any;
  handleCountrySelection: Function;
}
const MobileCountrySelection: React.FC<MobileCountrySelectionProps> = ({
  handleBack,
  showCountryModal = false,
  countryData,
  handleCountrySelection,
}) => {
  const [filteredCountryList, updateCountryList] = useState([]);
  useEffect(() => updateCountryList(countryData), [countryData]);
  const searchCountryHandler = (event: any): void => {
    const searchText = event ? event.target.value : "";
    const countryList = countryData.filter((item: any) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
    updateCountryList(countryList);
  };
  return (
    <Modal isOpen={showCountryModal} className="mobile-full-modal">
      <ModalHeader className="mobile-full-modal-header">
        <div className="d-flex align-items-center" style={{ columnGap: 12 }}>
          <Icon
            iconName="ChevronLeft"
            size={20}
            fill={getColorFromTheme("grey", "800")}
            onClick={() => handleBack()}
          />
          <Input
            placeholder="Search for your country here..."
            onChange={(e: any) => searchCountryHandler(e)}
            className="w-100"
            // value={searchText}
          />
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          {filteredCountryList &&
            filteredCountryList.map((item: any) => (
              <div
                onClick={() => {
                  sessionStorage.setItem("userCountryCode", item.iso_code_2);
                  handleCountrySelection(item, true);
                }}
                style={{ padding: 12 }}
              >
                {item.name}
              </div>
            ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MobileCountrySelection;
