import { UserInfoState, Points, GCB, Tokens } from "./types";

export const getUserDetails = (state: UserInfoState): any => state.userDetails;

export const getLoginStatus = (state: UserInfoState): boolean =>
  state.isLoggedIn;

export const getUserPoints = (state: UserInfoState): number =>
  state.userPoints.availablePoints;

export const getDetailedUserPoints = (state: UserInfoState): Points =>
  state.userPoints;

export const getUserFirstName = (state: UserInfoState): string => {
  const userDetails = getUserDetails(state);
  return userDetails ? userDetails.first_name : "";
};

export const getGCB = (state: UserInfoState): GCB => state.gcb;

export const getAuthToken = (state: UserInfoState): string => state.auth_token;

export const getIsAdmin = (state: UserInfoState): boolean => state.isAdmin;

export const getUserPlatform = (state: UserInfoState): string =>
  state.userPoints.platform;

export const getTokens = (state: UserInfoState): Tokens => state.xoxoTokens;
