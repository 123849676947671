import { ApiRoutesType, GraphQlQueryType } from "./api.types";

export const API_ROUTES: ApiRoutesType = {
  STORES: "/stores",
  VOUCHER: "/voucher",
  CONSUMER: "/consumer",
  VALIDATE_USER_EMAIL: "/validateUserIdentity",
  VALIDATE_PASSWORD: "/validatePassword",
  VALIDATE_LOGIN_OTP: "/validateLoginOtp",
  VALIDATE_SETUP_OTP: "/validateSetupOtp",
  RESEND_SETUP_OTP: "/resendSetupOtp",
  RESEND_LOGIN_OTP: "/resendLoginOtp",
  RESEND_VERIFICATION_LINK: "/sendVerificationLink",
  LOG_OUT: "/logout",
  POINTS: "/points",
  DEFAULT_ENDPOINT: "/api/graph",
  DEFAULT_PUBLIC_ENDPOINT: "/api/public/graph",
  UPDATE_VOUCHER_ORDER: "/payuCallbackVoucher",
  VOUCHER_ORDER_DETAIL: "/voucherOrderDetails",
  GET_PAYMENT_METHODS: "/getPaymentMethods",
  GET_PAYU_PAYMENT: "/payUPayment",
  EXP_ORDER_DETAIL: "/orderDetails",
  UPDATE_EXP_ORDER: "/payuCallback",
  APPLY_VOUCHER: "/applyVoucher",
  NO_PAYU_EXP_UPDATE_ORDER: "noPayCheckout",
  STORESADMIN_CLIENTELE_CHECK: "/storeAdmin",
  PIP: "/plum_tp_integrations",
  RESET_PASSWORD: "/generateResetPasswordLink",
  SWITCH_ACCOUNTS: "/switchapi",
};

export const GRAPHQL_QUERIES: GraphQlQueryType = {
  EXPERIENCE_LST: "experience.query.getExperiencesList",
  EXPERIENCE_CITIES_LST: "experience.query.getExperiencesCitiesList",
  EXPERIENCE_CATEGORIES_LST: "experience.query.getExperiencesCategoriesList",
  MERCHANDISE_CATEGORIES_LST: "experience.query.getMerchandiseCategoriesList",
  VOUCHER: "voucher.mutation.voucher_list",
  STATIC_VOUCHER_DETAIL: "voucher.mutation.static_voucher_data",
  PERKS_BRAND: "stores.mutation.newPerks",
  FETCH_PERKS: "stores.mutation.fetchPerks",
  COUNTRY_LIST: "stores.mutation.getCountries",
  USER_DETAILS: "rightnav.query.user_details_query",
  EXPERIENCE_CATEGORY: "stores.mutation.getUrlBasedCategories",
  VOUCHER_CATEGORY: "voucher.mutation.filter_list",
  PERKS_CATEGORY: "stores.mutation.perksCategories",
  PRODUCT_DATA: "stores.mutation.getProduct",
  VOUCHER_REPORT: "voucher.mutation.fetchEgiftBalance",
  POINTS: "points.query.getPoints",
  GIFT_VOUCHER_REPORT: "voucher.mutation.fetchGiftVoucherBalance",
  FETCH_TPD_REPORT: "voucher.mutation.fetchTpdBalanceList",
  ORDER_HISTORY: "voucher.mutation.getBookingHistory",
  EXPERIENCE_ORDER_HISTORY: "experience.query.getExperiencesOrderDetail",
  CHANGE_PASSWORD: "profile.mutation.change_query",
  EDIT_PROFILE: "stores.mutation.update_employee_data",
  GET_SEARCH_ITEMS: "stores.mutation.get_items",
  CUSTOM_LANDING: "stores.mutation.landing_details",
  URL_PREFERENCES: "stores.mutation.url_preferences",
  GET_ROUTES: "stores.mutation.getRoutes",
  PERKS_DETAILS: "stores.mutation.perksDetails",
  GCB: "stores.query.get_GCB",
  NEW_PERKS: "stores.mutation.newPerks",
  VOUCHER_CREATE_ORDER: "order.mutation.voucherCreateOrder",
  EXP_CREATE_ORDER: "order.mutation.createOrder",
  GET_MENU: "stores.query.getMenu",
  SIGN_UP: "stores.mutation.signUpStores",
  GET_PERKS_COUNTRIES: "stores.mutation.perksCountries",
  GET_VOUCHERS_COUNTRIES: "stores.mutation.filter_list",
  CLIENTELE_FUND_CHECK: "storesAdmin.query.getClientId",
  GET_XOXO_TOKENS: "stores.mutation.getXoxoToken",
  FETCH_PLATFORM_SETTING: "stores.mutation.fetchPlatformSetting",
  FETCH_USERS_PROFILE_LOGO: "stores.mutation.fetchUsersProfileLogo",
  GET_CURRENCY: "stores.query.getCurrency",
  PIP_GETPOINTS: "plum_tp_integrations.query.getPoints",
  PIP_GETUSERS: "plum_tp_integrations.query.getUserDetails",
  RESEND_VOUCHER: "stores.mutation.resendVoucher",
  PLUM_API: "stores.query.plumApi",
  BANNER_MODULE: "stores.query.getBannerModule",
  PLUM_API_ORDER: "stores.query.plumApiOrder",
  PLUM_API_APPLY_VOUCHER: "stores.query.applyVoucher",
  VALIDATE_RESET_LINK: "stores.query.validateResetLink",
  PLUM_API_CONSUMER: "stores.query.plumApiConsumer",
  PAYPAL_DETAILS: "order.mutation.paypalPayment",
  VOUCHER_ADD_TO_CART: "voucher.mutation.addToCart",
  VOUCHER_FETCH_CART: "voucher.mutation.fetchCart",
  RESEND_OTP: "stores.mutation.resendOtp",
  SEND_WELCOME_MAIL: "stores.mutation.sendWelcomeMail",
  PLUM_PRO_FILTERS: "plumProListing.mutation.fetchFilters",
  PLUM_PRO_VOUCHERS: "plumProListing.mutation.fetchVouchers",
  PLUM_PRO_PLANS: "plumProListing.query.getPlans",
  VOUCHER_DOWNLOAD: "plumProListing.query.downloadVoucherCatalogue",
  VALIDATE_IDENTITY: "stores.query.validateIdentity",
  USER_ASSOC: "stores.query.getUserAssoc",
  DOWNLOAD_SERVICE: "stores.mutation.fileDownload",
  UNSUBSCRIBE_MAIL: "stores.mutation.unSubscribeMail",
  GET_KYB_STATUS: "diy.query.get_kyb_status_for_kyb",
  GET_PAYMENT_METHODS: "stores.query.getPaymentMethods",
  GET_PAYU_DETAILS: "stores.query.getPayUDetails",
  GET_EXPERIENCE_BY_ID: "experience.query.fetchExpById",
  GET_COUNTRY: "storesAdmin.query.country_query",
  GET_STATES: "storesAdmin.query.state_query",
  GET_CITIES: "storesAdmin.query.city_query",
  GET_PRODUCT_AVAILABLE_DATES: "experience.mutation.fetchAvailableDates",
  CHECK_AVAILABILITY_PRICING: "experience.mutation.fetchAvailabilityPricing",
  EXPERIENCE_CREATE_ORDER: "experience.mutation.experienceCreateOrder",
  GET_SSO_TOKEN: "auth.mutation.sso_token",
  GET_PAYMENT_TOKEN: "stores.query.create_payment_token",
  GET_PAYMENT_STATUS: "stores.query.get_rzp_payment_status",
  GET_PRIMER_PAYMENT_STATUS: "stores.query.get_primer_payment_status",
};
