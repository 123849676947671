import React from "react";
import "./styles.scss";

interface BadgesProps {
  children: any;
  backgroundColor?: string;
  textColor?: string;
  customClass?: string;
}

const Badges: React.FC<BadgesProps> = ({
  children,
  backgroundColor = "#F6F6F6",
  textColor = "#4A4A4A",
  customClass = "",
}) => {
  return (
    <div
      className={`badgeTag ${customClass}`}
      style={{ backgroundColor, color: textColor }}
    >
      {children}
    </div>
  );
};

export default Badges;
