import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getRoutes } from "../store/appInfo/getters";
import PageNotFound from "../pages/pageNotFound/PageNotFound";

const CheckAccessRoute = ({ children, routes }: any) => {
  let isRouteActive = false;
  const paths = window.location?.pathname?.split("/");
  const route =
    paths[paths.length - 1] === ""
      ? paths[paths.length - 2]
      : paths[paths.length - 1];

  if (routes.includes(route)) {
    isRouteActive = true;
  }

  if (!isRouteActive) {
    return <PageNotFound />;
  }

  return children;
};

const mapStateToProps = ({ appInfo }: any) => ({
  routes: getRoutes(appInfo),
});

export default withRouter(connect(mapStateToProps)(CheckAccessRoute));
