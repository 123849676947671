import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes";
import configureStore from "./store";
import Bootstrap from "./Bootstrap-v2";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyle, theme, ThemeProvider } from "@plum-xoxo/stores-dsm";
// import * as Sentry from '@sentry/browser';

const { persistor, store } = configureStore();

const App: React.FC = () => (
  <div id="xoxoday-webapp">
    <Provider store={store}>
      <ThemeProvider theme={theme} customTheme={{
        palette: { //TODO: remove after updating with new colors
          grey: {
            100: '#FAFAFA',
            200: '#F2F3F2',
            300: '#CACACA',
            400: '#AAAAAA',
            500: '#8A8A8A',
            600: '#6A6A6A',
            700: '#4A4A4A',
            800: '#2A2A2A',
            900: '#0A0A0A',
          },
          white: {
            100: '#AAAAAA',
            200: '#C9C9C9',
            300: '#DCDCDC',
            400: '#E2E2E2',
            500: '#ECECEC',
            600: '#F0F0F0',
            700: '#F3F3F3',
            800: '#F6F6F6',
            900: '#FFFFFF',
          }
        }
      }}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <Router>
            <Bootstrap>
              <Routes />
              <ToastContainer
                theme="dark"
                icon={false}
                transition={Slide}
                hideProgressBar={true}
                autoClose={5000}
              />
            </Bootstrap>
          </Router>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </div>
);
export default App;
