import React, { Component } from "react";
import "./loaders.scss";

interface LoaderProps {
  position?: any;
  minHeight?: any;
  textAlign?: any;
  marginTop?: any;
}

interface LoaderState {}

class Loader extends Component<LoaderProps, LoaderState> {
  render() {
    const styles = {
      position: this.props.position ? this.props.position : "relative",
      minHeight: this.props.minHeight ? this.props.minHeight : "100px",
      textAlign: this.props.textAlign ? this.props.textAlign : "center",
      marginTop: this.props.marginTop ? this.props.marginTop : "20px",
    };

    return (
      <div className="col-lg-12 compLoader" style={styles}>
        <div className="loading">
          <img src="/img/loading.gif" alt="loader" width="30" height="30" />
        </div>
      </div>
    );
  }
}

interface FullPageLoderProps {
  autoSelectingPoints?: boolean;
}

export const FullPageLoader: React.FC<FullPageLoderProps> = ({
  autoSelectingPoints = false,
}) => {
  return (
    <div className="fullscreenLoader">
      {autoSelectingPoints ? (
        <div className="auto-selectPoints">
          Auto selecting your reward points. Please hang on....
        </div>
      ) : null}
    </div>
  );
};

export default Loader;
