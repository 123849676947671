import { OrderState } from "./types";

export const getOrderType = (state: OrderState): string => state.orderType;

export const getUsePoints = (state: OrderState): boolean => state.usePoints;

export const getOrderUserDetails = (state: OrderState): any =>
  state.orderData.checkoutUser;

export const getContribution = (state: OrderState): any => state.contribution;

export const getPaymentType = (state: OrderState): string =>
  state.orderData.paymentType;

export const getUseGCB = (state: OrderState): boolean => state.useGCB;

export const getAppliedVouchers = (state: OrderState): any =>
  state.appliedVoucherArray;

export const getUseTokens = (state: OrderState): boolean => state.useTokens;
