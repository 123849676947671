import React, { Children, SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import isObject from "lodash/isObject";
import { connect } from "react-redux";
import { NavLink } from "reactstrap";
import { StoreState } from "../../../../store/store.state";
import { getLoginStatus } from "../../../../store/user/getters";
import { getBranding } from "../../../../store/appInfo/getters";

interface PlumLinkProps {
  branding?: any;
  url?: String | object;
  title?: String;
  type?: String;
  className?: string;
  children?: any;
  target?: any;
  onClick?: any;
  style?: any;
  href?: string;
  id?: string;
}
interface PlumLinkState {}

class PlumLink extends React.Component<PlumLinkProps, PlumLinkState> {
  customeLink = () => {
    const { url = "", branding }: any = this.props;

    let customUrl =
      branding && branding.custom_url
        ? branding.custom_url === "/"
          ? "/marketplace"
          : branding.custom_url
        : "";

    if (isObject(url)) {
      let urlObject: any = isObject(url) ? Object.assign({}, url) : "";
      urlObject = urlObject.pathname = customUrl + urlObject.pathname;
      return urlObject;
    }
    return customUrl + url;
  };

  onClickHandler = (e: SyntheticEvent) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      type = "",
      className = "",
      children,
      target = "",
      style,
      href = "",
      id,
    } = this.props;

    const customlink = this.customeLink();

    const link = () => {
      switch (type) {
        case "ANCHOR":
          return (
            <a
              href={customlink}
              target={target}
              className={className ? className : ""}
              id={id}
            >
              {children}
            </a>
          );
          break;
        case "ANCHOR_EXT":
          return (
            <a
              href={href}
              target={target || "_blank"}
              className={className ? className : ""}
              rel="noreferrer"
              id={id}
            >
              {children}
            </a>
          );
          break;

        case "NavLink":
          return (
            <NavLink
              href={customlink ? customlink : "javascript.void()"}
              onClick={this.onClickHandler}
              className={className ? className : ""}
              style={style}
              id={id}
            >
              {children}
            </NavLink>
          );
          break;

        default:
          return (
            <Link
              to={customlink}
              className={className}
              onClick={this.onClickHandler}
              id={id}
            >
              {children}
            </Link>
          );
          break;
      }
    };

    return <>{link()}</>;
  }
}

const mapStateToProps = ({ user, appInfo }: StoreState) => ({
  isLoggedIn: getLoginStatus(user),
  branding: getBranding(appInfo),
});

export default connect(mapStateToProps, null)(PlumLink);
