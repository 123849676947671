import { AppInfoActionTypes } from "./types";

export enum ActionTypes {
  SET_COUNTRY_LIST = "SET COUNTRY LIST",
  SET_USER_COUNTRY = "SET USER COUNTRY",
  SET_USER_COUNTRY_CODE = "SET_USER_COUNTRY_CODE",
  SET_USER_DETAILS = "SET USER DETAILS",
  SET_LOGIN_STATUS = "SET LOGIN STATUS",
  SET_BRANDING = "SET BRANDING",
  SET_ROUTES = "SET ROUTES",
  SET_SEARCHED_KEY = "SET_SEARCHED_KEY",
  SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY",
  SET_ORIGIN_COUNTRY_CODE = "SET_ORIGIN_COUNTRY_CODE",
  // SET_PERKS_COUNTRY_LIST= "SET_PERKS_COUNTRY_LIST",
  SET_VOUCHER_COUNTRY_LIST = "SET_VOUCHER_COUNTRY_LIST",
  SET_MERCH_COUNTRY_LIST = "SET_MERCH_COUNTRY_LIST",
  SET_EXPERIENCE_COUNTRY_LIST = "SET_EXPERIENCE_COUNTRY_LIST",
  SET_CATEGORY_ID = "SET_CATEGORY_ID",
  SET_PLATFORM_SETTINGS = "SET_PLATFORM_SETTINGS",
  SET_VOUCHER_CATEGORY_LIST = "SET_VOUCHER_CATEGORY_LIST",
  SET_MERCHANDISE_CATEGORY_LIST = "SET_MERCHANDISE_CATEGORY_LIST",
  SET_EXPERIENCE_CATEGORY_LIST = "SET_EXPERIENCE_CATEGORY_LIST",
  SET_LINKED_ACCOUNTS = "SET_LINKED_ACCOUNTS",
  TOGGLE_LINKED_ACCOUNTS_MODAL = "TOGGLE_LINKED_ACCOUNTS_MODAL",
  SET_SHOW_ALTERNATE_EMAIL = "SET_SHOW_ALTERNATE_EMAIL",
  SET_EMAIL_STATUS = "SET_EMAIL_STATUS",
  RESET_ALTERNATE_EMAIL_DATA = "RESET_ALTERNATE_EMAIL_DATA",
  SET_SHOW_MULTILANGUAGE_COMPANY = "SET_SHOW_MULTILANGUAGE_COMPANY",
  RESET_MULTI_ACCOUNTS_DATA = "RESET_MULTI_ACCOUNTS_DATA",
  SET_PHN_NUM_DISABLE_DATA = "SET_PHN_NUM_DISABLE_DATA",
  SET_USER_JOURNEY_STATE = "SET_USER_JOURNEY_STATE",
  SET_SHOW_CTS = "SET_SHOW_CTS",
}

export const setCategoryId = (categoryId: any): AppInfoActionTypes => {
  return {
    type: ActionTypes.SET_CATEGORY_ID,
    payload: { categoryId },
  };
};

export const setVoucherCategoryList = (
  voucherCategoryList: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_VOUCHER_CATEGORY_LIST,
  payload: { voucherCategoryList },
});

export const setMerchandiseCategoryList = (
  merchandiseCategoryList: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_MERCHANDISE_CATEGORY_LIST,
  payload: { merchandiseCategoryList },
});

export const setExperienceCategoryList = (
  experienceCategoryList: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_EXPERIENCE_CATEGORY_LIST,
  payload: { experienceCategoryList },
});

export const setPlatformSettings = (
  platFormSettings: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_PLATFORM_SETTINGS,
  payload: { platFormSettings },
});

export const setCountryList = (countryList: any): AppInfoActionTypes => ({
  type: ActionTypes.SET_COUNTRY_LIST,
  payload: { countryList },
});

// export const setPerksCountryList = (perksCountryList: any): AppInfoActionTypes => ({
// 	type: ActionTypes.SET_PERKS_COUNTRY_LIST,
// 	payload: { perksCountryList },
// });

export const setVoucherCountryList = (
  voucherCountryList: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_VOUCHER_COUNTRY_LIST,
  payload: { voucherCountryList },
});

export const setMerchCountryList = (
  merchCountryList: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_MERCH_COUNTRY_LIST,
  payload: { merchCountryList },
});


export const setExperienceCountryList = (
  experienceCountryList: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_EXPERIENCE_COUNTRY_LIST,
  payload: { experienceCountryList },
});

export const setUserCountry = (userCountry: number): AppInfoActionTypes => ({
  type: ActionTypes.SET_USER_COUNTRY,
  payload: { userCountry },
});

export const setUserCountryCode = (
  userCountryCode: string
): AppInfoActionTypes => ({
  type: ActionTypes.SET_USER_COUNTRY_CODE,
  payload: { userCountryCode },
});

export const setBranding = (branding: any): AppInfoActionTypes => ({
  type: ActionTypes.SET_BRANDING,
  payload: { branding },
});

export const setRoutes = (routes: any): AppInfoActionTypes => ({
  type: ActionTypes.SET_ROUTES,
  payload: { routes },
});

export const setSearchedKey = (searchedKey: any): AppInfoActionTypes => ({
  type: ActionTypes.SET_SEARCHED_KEY,
  payload: { searchedKey },
});

export const setSelectedCountry = (
  selectedCountry: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_SELECTED_COUNTRY,
  payload: { selectedCountry },
});

export const setOriginCountryCode = (
  originCountryCode: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_ORIGIN_COUNTRY_CODE,
  payload: { originCountryCode },
});

export const setLinkedAccounts = (linkedAccounts: any): AppInfoActionTypes => ({
  type: ActionTypes.SET_LINKED_ACCOUNTS,
  payload: { linkedAccounts },
});

export const toggleLinkedAccountsModal = (data: any): AppInfoActionTypes => ({
  type: ActionTypes.TOGGLE_LINKED_ACCOUNTS_MODAL,
  payload: { data },
});

export const setShowAlternateEmail = (
  showAlternateEmail: boolean
): AppInfoActionTypes => ({
  type: ActionTypes.SET_SHOW_ALTERNATE_EMAIL,
  payload: { showAlternateEmail },
});

export const setIsEmailExists = (
  isPrimaryMailExists: boolean
): AppInfoActionTypes => ({
  type: ActionTypes.SET_EMAIL_STATUS,
  payload: { isPrimaryMailExists },
});

export const resetAlternateEmailData = (): AppInfoActionTypes => ({
  type: ActionTypes.RESET_ALTERNATE_EMAIL_DATA,
  payload: {},
});

export const setShowMultiLanguage = (
  showMultiLanguage: boolean
): AppInfoActionTypes => ({
  type: ActionTypes.SET_SHOW_MULTILANGUAGE_COMPANY,
  payload: { showMultiLanguage },
});

export const resetMultiAccountsData = (): AppInfoActionTypes => ({
  type: ActionTypes.RESET_MULTI_ACCOUNTS_DATA,
  payload: {},
});

export const setPhnNumDisableData = (
  disablePhoneNumberInCheckout: boolean
): AppInfoActionTypes => ({
  type: ActionTypes.SET_PHN_NUM_DISABLE_DATA,
  payload: { disablePhoneNumberInCheckout },
});

export const setUserJourneyState = (
  userJourneyUseCase: any
): AppInfoActionTypes => ({
  type: ActionTypes.SET_USER_JOURNEY_STATE,
  payload: { userJourneyUseCase },
});

export const setShowCts = (showCts: boolean): AppInfoActionTypes => ({
  type: ActionTypes.SET_SHOW_CTS,
  payload: { showCts },
});
