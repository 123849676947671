import React from "react";

interface Props {
  onAccept: any;
}
export class MyCustomCookieBanner extends React.Component<Props> {
  render() {
    return (
      <>
        <div className="react-cookie-banner ">
          <div
            className="d-inline-block msg-wrap"
            style={{ verticalAlign: "middle" }}
          >
            <h3 className="d-block">Your Privacy</h3>
            <span
              className="cookie-message"
              style={{ verticalAlign: "middle" }}
            >
              This site uses cookies to make your experience with Xoxoday Plum
              better. By using this site, you accept our use of cookies. &nbsp;
              <a
                className="cookie-link"
                href="https://www.xoxoday.com/privacy-policy#5-intellectual"
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              to view our Privacy Policy in detail.
            </span>
          </div>

          <div className="cookie-btn-wrap" style={{ verticalAlign: "middle" }}>
            <button
              className="btn btn-secondary button-close"
              onClick={this.props.onAccept}
            >
              OK
            </button>
          </div>
        </div>
      </>
    );
  }
}
export default MyCustomCookieBanner;
