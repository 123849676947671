//import gtm event module
//webengage module
import TagManager from "react-gtm-module";

declare global {
  interface Window {
    webengage: any;
  }
  interface Window {
    dataLayer: any;
  }
}

class PlumAnalytics {
  event(eventName: any, params: object, additionalParams = {}) {
    window.webengage.track(eventName, params);
  }

  login(params: any) {
    window.webengage.user.login(params.gtmUserKey || "");
    window.webengage.user.setAttribute("we_email", params.email || "");
    window.webengage.user.setAttribute("we_phone", params.contact || "");
    window.webengage.user.setAttribute(
      "we_first_name",
      params.first_name || ""
    );
    window.webengage.user.setAttribute("we_last_name", params.last_name || "");
    window.webengage.user.setAttribute(
      "we_company",
      params.gtmCompanyKey || ""
    );
  }

  logout(params = {}) {
    window.webengage.user.logout();
  }

  gtmAction(params: any) {
    window.dataLayer.push(params);
  }
}

export default new PlumAnalytics();
