import { combineReducers } from "redux";
import { StoreState } from "./store.state";
import { appInfoReducer } from "./appInfo/reducer";
import { cartReducer } from "./cart/reducer";
import { homePageReducer } from "./home/reducer";
import { userInfoReducer } from "./user/reducer";
import { orderReducer } from "./order/reducer";
import { expOrderReducer } from "./experience/reducer";

const reducers = combineReducers<StoreState>({
  home: homePageReducer,
  appInfo: appInfoReducer,
  cart: cartReducer,
  user: userInfoReducer,
  order: orderReducer,
  experience: expOrderReducer,
});

export default reducers;
