import { CartActionTypes, VoucherCartItem } from "./types";
export enum ActionTypes {
  ADD_VOUCHER_TO_CART = "ADD VOUCHER TO CART",
  ADD_EXPERIENCE_TO_CART = "ADD EXPERIENCE TO CART",
  REMOVE_VOUCHER_FROM_CART = "REMOVE VOUCHER FROM CART",
  REMOVE_EXPERIENCE_FROM_CART = "REMOVE EXPERIENCE FROM CART",
  UPDATE_VOUCHER_QUANTITY = "UPDATE VOUCHER QUANTITY",
  USE_POINTS = "USE POINTS",
  RESET_COUPON = "RESET COUPON",
  SET_COUPON = "SET COUPON",
  RESET_CART = "RESET CART",
  UPDATE_VOUCHER_CART_PRODUCTS = "UPDATE_VOUCHER_CART_PRODUCTS",
  ADD_TO_CART_ERROR = "ADD_TO_CART_ERROR",
  FETCHING_CART_DATA = "FETCHING_CART_DATA",
  FETCHING_CART_ERROR = "FETCHING_CART_ERROR",
  CART_ACTION_LOADER = "CART_ACTION_LOADER",
  REDIRECT_TO_CHECKOUT = "REDIRECT_TO_CHECKOUT",
  CONVERSION_TO_BASE = "CONVERSION_TO_BASE",
  CART_CONVERSION_TO_INR = "CART_CONVERSION_TO_INR",
  POINT_CONVERSION_MULTIPLIER = "POINT_CONVERSION_MULTIPLIER",
}

export const addToCartError = (error: any): CartActionTypes => ({
  type: ActionTypes.FETCHING_CART_ERROR,
  payload: { error },
});

export const addVoucherToCart = (
  voucher: VoucherCartItem
): CartActionTypes => ({
  type: ActionTypes.ADD_VOUCHER_TO_CART,
  payload: { voucher },
});

export const removeVoucherFromCart = (
  voucher: VoucherCartItem
): CartActionTypes => ({
  type: ActionTypes.REMOVE_VOUCHER_FROM_CART,
  payload: { voucher },
});

export const updateVoucherQuantity = (
  voucher: VoucherCartItem
): CartActionTypes => ({
  type: ActionTypes.UPDATE_VOUCHER_QUANTITY,
  payload: { voucher },
});

export const redeemPointsForPayment = (bool: boolean): CartActionTypes => ({
  type: ActionTypes.USE_POINTS,
  payload: bool,
});

export const resetCoupon = (): CartActionTypes => ({
  type: ActionTypes.RESET_COUPON,
  payload: true,
});

export const setCoupon = (couponDetails: any): CartActionTypes => ({
  type: ActionTypes.SET_COUPON,
  payload: { couponDetails },
});

export const resetCart = (): CartActionTypes => ({
  type: ActionTypes.RESET_CART,
  payload: true,
});

export const updateVoucherCartProducts = (
  newCartVouchers: any
): CartActionTypes => ({
  type: ActionTypes.UPDATE_VOUCHER_CART_PRODUCTS,
  payload: { newCartVouchers },
});

export const fetchingCartData = (status: boolean): CartActionTypes => ({
  type: ActionTypes.FETCHING_CART_DATA,
  payload: { status },
});

export const fetchingCartError = (error: any): CartActionTypes => ({
  type: ActionTypes.FETCHING_CART_ERROR,
  payload: { error },
});

export const conversionToBase = (conversion_to_base: any): CartActionTypes => ({
  type: ActionTypes.CONVERSION_TO_BASE,
  payload: { conversion_to_base },
});

export const cartConversionToInr = (
  cart_conversion_to_inr: any
): CartActionTypes => ({
  type: ActionTypes.CART_CONVERSION_TO_INR,
  payload: { cart_conversion_to_inr },
});

export const pointConversionMultiplier = (
  pointConversionMultiplier: any
): CartActionTypes => ({
  type: ActionTypes.POINT_CONVERSION_MULTIPLIER,
  payload: { pointConversionMultiplier },
});

export const redirectToCheckout = (
  redirectToCheckout: boolean
): CartActionTypes => ({
  type: ActionTypes.REDIRECT_TO_CHECKOUT,
  payload: { redirectToCheckout },
});
