import { HomeState, HomepageActionTypes } from "./types";
import { ActionTypes } from "./actions";

const initialState: HomeState = {
  experienceSection: {
    isFetching: false,
    data: null,
    error: null,
  },
  vouchersSection: {
    isFetching: false,
    data: null,
    error: null,
  },
  perksSection: {
    isFetching: false,
    data: null,
    error: null,
  },
};

export const homePageReducer = (
  state: HomeState = initialState,
  action: HomepageActionTypes
): HomeState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.EXPERIENCE_DATA_FETCHING:
      return {
        ...state,
        experienceSection: {
          ...state.experienceSection,
          isFetching: true,
        },
      };

    case ActionTypes.EXPERIENCE_DATA_FETCHED:
      return {
        ...state,
        experienceSection: {
          isFetching: false,
          error: null,
          data: (payload as any).data,
        },
      };

    case ActionTypes.EXPERIENCE_DATA_FETCHING_FAILED:
      return {
        ...state,
        experienceSection: {
          ...state.experienceSection,
          isFetching: false,
          error: (payload as any).error,
        },
      };

    case ActionTypes.VOUCHERS_DATA_FETCHING:
      return {
        ...state,
        vouchersSection: {
          ...state.vouchersSection,
          isFetching: true,
        },
      };

    case ActionTypes.VOUCHERS_DATA_FETCHED:
      return {
        ...state,
        vouchersSection: {
          isFetching: false,
          error: null,
          data: (payload as any).data,
        },
      };

    case ActionTypes.VOUCHERS_DATA_FETCHING_FAILED:
      return {
        ...state,
        vouchersSection: {
          ...state.vouchersSection,
          isFetching: false,
          error: (payload as any).error,
        },
      };

    case ActionTypes.PERKS_DATA_FETCHING:
      return {
        ...state,
        perksSection: {
          ...state.perksSection,
          isFetching: true,
        },
      };

    case ActionTypes.PERKS_DATA_FETCHED:
      return {
        ...state,
        perksSection: {
          isFetching: false,
          error: null,
          data: (payload as any).data,
        },
      };

    case ActionTypes.PERKS_DATA_FETCHING_FAILED:
      return {
        ...state,
        perksSection: {
          ...state.perksSection,
          isFetching: false,
          error: (payload as any).error,
        },
      };

    default:
      return state;
  }
};
