import React from "react";

interface HeaderMenuDropdownProps {
  children?: React.ReactNode;
  customWidth?: number;
  customMinWidth?: number;
  top?: number;
  type?: string;
  customClass?: string;
}

const HeaderMenuDropdown: React.FC<HeaderMenuDropdownProps> = ({
  children,
  customWidth,
  customMinWidth,
  top = 36,
  type = "",
  customClass = "",
}) => {
  return (
    <div
      className={`dropdown ${customClass}`}
      style={{
        width: customWidth,
        minWidth: customMinWidth ? customMinWidth : customWidth,
        top,
        display: `${type === "search" ? "block" : null}`,
      }}
    >
      {children}
    </div>
  );
};

export default HeaderMenuDropdown;
